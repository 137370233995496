/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItemWithCheck from "./DropdownItemWithCheck";

export default function SearchBar({label, value, setValue, typeNames = undefined, typeValues = undefined, type = undefined, setType = undefined}) {

    const dropdownItems = typeValues ? typeValues.map((typeValue, index) => {
        return (
            <DropdownItemWithCheck key={typeValue} label={typeNames[index]} active={type === typeValue} onClick={() => {setType(typeValue)}}/>
        )
    }) : '';

    const currentFilter = typeValues ? typeNames[typeValues.indexOf(type)] : '';

    return (
        <InputGroup>
            <InputGroup.Text><FontAwesomeIcon icon={faSearch} style={{color: 'black'}}/></InputGroup.Text>
            <Form.Control type='text' value={value} placeholder={label} onChange={(event) => {setValue(event.target.value)}}/>
            { typeValues &&
                <Dropdown>
                    <Dropdown.Toggle style={{borderTopRightRadius: 6, borderBottomRightRadius: 6}} variant={'outline-primary'}>
                        {currentFilter ? currentFilter : ' '}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {dropdownItems}
                    </Dropdown.Menu>
                </Dropdown>
            }
        </InputGroup>
    )
}