/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Form, ListGroup, Modal } from 'react-bootstrap';
import { ApiRequest } from '../../ApiManager.tsx';
import DropdownControl from '../../components/DropdownControl';
import CustomButton from '../../components/CustomButton.js';
import LoadingWrapper from '../../components/LoadingWrapper.js';
import QuickTable from '../../components/QuickTable.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../components/SearchBar.js';

export default function MatchPaychexWorkers() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanyIdentifier, setSelectedCompanyIdentifier] = useState<number>();

  const [workers, setWorkers] = useState<any[]>([]);
  const [employees, setEmployees] = useState<any[]>([]);

  const [employeeSearch, setEmployeeSearch] = useState('');
  const [workerSearch, setWorkerSearch] = useState('');

  const sortedWorkers = useMemo(() => {
    return workers.sort((a: any, b: any) => {
      if (`${a.lastName}${a.firstName}${a.middleName ?? ''}` < `${b.lastName}${b.firstName}${b.middleName ?? ''}`) {
        return -1;
      } else if (
        `${a.lastName}${a.firstName}${a.middleName ?? ''}` > `${b.lastName}${b.firstName}${b.middleName ?? ''}`
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [workers]);

  const sortedEmployees = useMemo(() => {
    return employees.sort((a: any, b: any) => {
      if (`${a.lastName}${a.firstName}${a.middleName ?? ''}` < `${b.lastName}${b.firstName}${b.middleName ?? ''}`) {
        return -1;
      } else if (
        `${a.lastName}${a.firstName}${a.middleName ?? ''}` > `${b.lastName}${b.firstName}${b.middleName ?? ''}`
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [employees]);

  const [matches, setMatches] = useState<any[]>([]);

  const [selectedCompanyUserIdentifier, setSelectedCompanyUserIdentifier] = useState<number>();
  const [selectedPaychexId, setSelectedPaychexId] = useState<number>();

  const [showTerminated, setShowTerminated] = useState(false);

  const loadCompanies = useCallback(() => {
    new ApiRequest('admin', 'getCompaniesWithPaychexAccounts', setIsLoading, (response) => {
      setCompanies(response.companies);
    })
      .withNoAlertOnSuccess()
      .send();
  }, [setIsLoading, setCompanies]);

  useEffect(loadCompanies, []);

  const loadData = useCallback(() => {
    new ApiRequest('admin', 'getCompanyPaychexWorkers', setIsLoading, (response) => {
      setWorkers(response.workers);
      setEmployees(response.employees);
      autoMatch(response.employees, response.workers);
    })
      .withData({ companyIdentifier: selectedCompanyIdentifier })
      .withNoAlertOnSuccess()
      .send();
  }, [selectedCompanyIdentifier, setIsLoading, setWorkers, setEmployees]);

  useEffect(() => {
    if (!!selectedCompanyIdentifier) {
      loadData();
    }
  }, [selectedCompanyIdentifier]);

  const handleSubmit = useCallback(() => {
    new ApiRequest('admin', 'matchCompanyPaychexWorkers', setIsSubmitting, (response) => {})
      .withData({ matches: matches })
      .send();
  }, [matches, setIsSubmitting]);

  const autoMatch = useCallback(
    (employees: any[], workers: any[]) => {
      const autoMatches: any[] = [];

      employees
        .filter((e) => !e.paychexId)
        .forEach((employee) => {
          const workerMatches = workers.filter((w) => {
            return (
              w.firstName.toLowerCase() == employee.firstName.toLowerCase() &&
              w.lastName.toLowerCase() == employee.lastName.toLowerCase()
            );
          });
          if (workerMatches.length == 1) {
            autoMatches.push({
              uid: employee.companyUserUid,
              paychexId: workerMatches[0].paychexId,
              paychexEmployeeId: workerMatches[0].paychexEmployeeId,
            });
          }
        });

      setMatches(autoMatches);
    },
    [setMatches]
  );

  const matchesSorted = useMemo(() => {
    return matches.sort((a: any, b: any) => {
      const aEmployee = employees.find((e) => e.companyUserUid == a.uid);
      const bEmployee = employees.find((e) => e.companyUserUid == b.uid);

      if (
        `${aEmployee.lastName}${aEmployee.firstName}${aEmployee.middleName ?? ''}` <
        `${bEmployee.lastName}${bEmployee.firstName}${bEmployee.middleName ?? ''}`
      ) {
        return -1;
      } else if (
        `${aEmployee.lastName}${aEmployee.firstName}${aEmployee.middleName ?? ''}` >
        `${bEmployee.lastName}${bEmployee.firstName}${bEmployee.middleName ?? ''}`
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [matches, employees]);

  const matchRows = useMemo(() => {
    return matchesSorted.map((match) => {
      const employee = employees.find((e) => e.companyUserUid == match.uid);
      const worker = workers.find((w) => w.paychexId == match.paychexId);

      return (
        <tr key={match.uid}>
          <td>{`${employee.lastName}, ${employee.firstName}`}</td>
          <td>{`${worker.lastName}, ${worker.firstName}`}</td>
          <td>{worker.paychexId}</td>
          <td>{worker.paychexEmployeeId}</td>
          <td>{worker.terminated ? 'Terminated' : 'Active'}</td>
          <td>
            <button
              style={{ border: 'none', color: 'red', boxShadow: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                setMatches(matches.filter((m) => m.uid != match.uid));
              }}
            >
              <FontAwesomeIcon icon={faX} />
            </button>
          </td>
        </tr>
      );
    });
  }, [matches, setMatches, employees, workers]);

  const unMatchedEmployees = useMemo(() => {
    return sortedEmployees.filter((e) => !matches.some((m) => m.uid == e.companyUserUid) && !e.paychexId);
  }, [matches, sortedEmployees]);

  const unMatchedFilteredEmployees = useMemo(() => {
    return unMatchedEmployees.filter(e => `${e.lastName}, ${e.firstName} ${e.middleName ?? ''}`.toLowerCase().includes(employeeSearch.toLowerCase()));
  }, [unMatchedEmployees, employeeSearch])

  const employeeRows = useMemo(() => {
    return unMatchedFilteredEmployees.map((e) => {
      const isSelected = selectedCompanyUserIdentifier == e.companyUserUid;

      return (
        <ListGroup.Item
          key={e.companyUserUid}
          style={isSelected ? { backgroundColor: 'var(--bs-primary)', color: 'white' } : { cursor: 'pointer' }}
          onClick={() => {
            setSelectedCompanyUserIdentifier(e.companyUserUid);
          }}
        >
          {`${e.lastName}, ${e.firstName} ${e.middleName ?? ''}`}
        </ListGroup.Item>
      );
    });
  }, [unMatchedFilteredEmployees, selectedCompanyUserIdentifier]);

  const unMatchedWorkers = useMemo(() => {
    return sortedWorkers.filter(
      (w) =>
        !matches.some((m) => m.paychexId == w.paychexId) &&
        !employees.some((e) => e.paychexId == w.paychexId) &&
        (showTerminated || !w.terminated)
    );
  }, [sortedWorkers, matches, employees, showTerminated, workerSearch]);

  const unMatchedFilteredWorkers = useMemo(() => {
    return unMatchedWorkers.filter(w => `${w.lastName}, ${w.firstName} ${w.middleName ?? ''}`.toLowerCase().includes(workerSearch.toLowerCase()));
  }, [unMatchedWorkers, workerSearch])

  const workerRows = useMemo(() => {
    return unMatchedFilteredWorkers.map((w) => {
      const isSelected = selectedPaychexId == w.paychexId;

      return (
        <ListGroup.Item
          key={w.paychexId}
          style={isSelected ? { backgroundColor: 'var(--bs-primary)', color: 'white' } : { cursor: 'pointer' }}
          onClick={() => {
            setSelectedPaychexId(w.paychexId);
          }}
        >
          {`${w.lastName}, ${w.firstName} ${w.middleName ?? ''}${w.terminated ? ` (Terminated)` : ''}`}
        </ListGroup.Item>
      );
    });
  }, [unMatchedFilteredWorkers, selectedPaychexId]);

  useEffect(() => {
    if (!!selectedCompanyUserIdentifier && !!selectedPaychexId) {
      const paychexEmployeeId = workers.find((w) => w.paychexId == selectedPaychexId)?.paychexEmployeeId;
      setMatches([
        ...matches,
        { uid: selectedCompanyUserIdentifier, paychexId: selectedPaychexId, paychexEmployeeId: paychexEmployeeId },
      ]);
      setSelectedCompanyUserIdentifier(undefined);
      setSelectedPaychexId(undefined);
    }
  }, [selectedCompanyUserIdentifier, selectedPaychexId]);

  const preexistingMatches = useMemo(() => {
    return employees.filter((e) => workers.some((w) => w.paychexId == e.paychexId));
  }, [employees, workers]);

  const preexistingMatchRows = useMemo(() => {
    return preexistingMatches.map((pm) => {
      const worker = workers.find((w) => w.paychexId == pm.paychexId);

      return (
        <tr key={pm.companyUserUid}>
          <td>{`${pm.lastName}, ${pm.firstName}`}</td>
          <td>{`${worker.lastName}, ${worker.firstName}`}</td>
          <td>{worker.paychexId}</td>
          <td>{worker.paychexEmployeeId}</td>
          <td>{worker.terminated ? 'Terminated' : 'Active'}</td>
          <td>
            <button
              style={{ border: 'none', color: 'red', boxShadow: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                setEmployees([
                  ...employees.filter((e) => e.companyUserUid != pm.companyUserUid),
                  { ...pm, paychexId: null },
                ]);
              }}
            >
              <FontAwesomeIcon icon={faX} />
            </button>
          </td>
        </tr>
      );
    });
  }, [preexistingMatches, workers, setEmployees]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Match Paychex Workers</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
        <LoadingWrapper isLoading={isLoading}>
          <DropdownControl
            title="TTA Company"
            itemNames={companies.map((c) => c.companyName)}
            itemValues={companies.map((c) => c.uid)}
            selection={selectedCompanyIdentifier}
            setSelection={setSelectedCompanyIdentifier}
          />

          <hr />
          {!!selectedCompanyIdentifier && (
            <>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{`Show/Hide Candidates (${unMatchedEmployees.length}, ${unMatchedWorkers.length})`}</Accordion.Header>
                  <Accordion.Body>
                    <div style={{ padding: 12, display: 'flex', gap: 12, flexWrap: 'wrap'}}>
                      <div style={{ color: 'black', flex: 1, display: 'flex', flexDirection: 'column', gap: 6, minWidth: 300 }}>
                        <SearchBar value={employeeSearch} setValue={setEmployeeSearch} label="Search System Users" />
                        <ListGroup>{employeeRows}</ListGroup>
                      </div>
                      <div style={{ backgroundColor: 'lightgray', width: 1 }} />
                      <div style={{ color: 'black', flex: 1, display: 'flex', flexDirection: 'column', gap: 6, minWidth: 300 }}>
                        <div style={{display: 'flex', whiteSpace: 'nowrap', gap: 6, alignItems: 'center'}}>
                          <SearchBar value={workerSearch} setValue={setWorkerSearch} label="Search Paychex Workers" />
                          <div style={{height: 38, border: '1px solid lightgray', borderRadius: 6, display: 'flex', alignItems: 'center', padding: '0px 6px'}}>

                          <Form.Check type='switch' style={{ margin: 0}} checked={showTerminated} onChange={() => {setShowTerminated(!showTerminated)}} label='Show Terminated' reverse/>
                          </div>
                        </div>

                        {/* <SwitchControl value={showTerminated} setValue={setShowTerminated} title="Show Terminated" /> */}
                        <ListGroup>{workerRows}</ListGroup>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{`Show/Hide Matches (${matchRows.length})`}</Accordion.Header>
                  <Accordion.Body>
                    <QuickTable
                      headers={['System Name', 'Paychex Name', 'Worker ID', 'Employee ID', 'Paychex Status', 'Remove']}
                      rows={matchRows}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{`Show/Hide Preexisting Matches (${preexistingMatchRows.length})`}</Accordion.Header>
                  <Accordion.Body>
                    <QuickTable
                      headers={['System Name', 'Paychex Name', 'Worker ID', 'Employee ID', 'Paychex Status', 'Remove']}
                      rows={preexistingMatchRows}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}
        </LoadingWrapper>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          label={`Submit ${matches.length} Matches`}
          isLoading={isSubmitting}
          disabled={matches.length == 0}
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </>
  );
}
