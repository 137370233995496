/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from "react"
import { View, Page, Document, Text, Image } from "@react-pdf/renderer";
import Cookies from "universal-cookie";
import PDFTable, { PDFTableRow } from "../../components/PDFTable";
import { usdFormatter, validateDecimal, validateInteger } from "../../tools";

export default function BudgetPDF({budget}) {
    ///////////////////////
    /// EXPENSES ROWS
    ///////////////////////
    const expensesRows = [
        {
            key: 'lightDailyWage',
            label: 'Driver Daily Wage (Light)',
            frequency: 'Daily',
            value: budget.budgetExpenses.driverWageLight ? usdFormatter.format(validateDecimal(budget.budgetExpenses.driverWageLight)/8) + '/hr x 8hrs' : '$0.00'
        },
        {
            key: 'standardDailyWage',
            label: 'Driver Daily Wage (Standard)',
            frequency: 'Daily',
            value: budget.budgetExpenses.driverWageStandard ? usdFormatter.format(validateDecimal(budget.budgetExpenses.driverWageStandard)/8) + '/hr x 8hrs' : '$0.00'
        },
        {
            key: 'straightDailyWage',
            label: 'Driver Daily Wage (Straight)',
            frequency: 'Daily',
            value: budget.budgetExpenses.driverWageStraight ? usdFormatter.format(validateDecimal(budget.budgetExpenses.driverWageStraight)/8) + '/hr x 8hrs' : '$0.00'
        },
        {
            key: 'aoWages',
            label: 'AO Wages',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.aoWages))
        },
        {
            key: 'bcWages',
            label: 'BC Wages',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.bcWages))
        },
        {
            key: 'adminWages',
            label: 'Admin Wages',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.adminWages))
        },
        {
            key: 'driverPto',
            label: 'Driver PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.driverPto))
        },
        {
            key: 'aoPto',
            label: 'AO PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.aoPto))
        },
        {
            key: 'bcPto',
            label: 'BC PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.bcPto))
        },
        {
            key: 'adminPto',
            label: 'Admin PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.adminPto))
        },
        {
            key: 'uniforms',
            label: 'Uniforms',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.uniforms))
        },
        {
            key: 'payrollTaxes',
            label: 'Payroll Taxes',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.payrollTaxes) + '%'
        },
        {
            key: 'driverWorkersComp',
            label: 'Driver\'s Workers Comp',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.driverWorkersComp) + '%'
        },
        {
            key: 'bcWorkersComp',
            label: 'BC Workers Comp',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.bcWorkersComp) + '%'
        },
        {
            key: 'healthInsurance',
            label: 'Health Insurance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.healthInsurance))
        },
        {
            key: 'lifeInsurance',
            label: 'Life Insurance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.lifeInsurance))
        },
        {
            key: 'dentalAndVision',
            label: 'Dental & Vision',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.dentalAndVision))
        },
        {
            key: 'pensionAndProfitSharing',
            label: 'Pension/Profit Sharing',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.pensionAndProfitSharing))
        },
        {
            key: 'drugScreens',
            label: 'Drug Screens',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.drugScreens))
        },
        {
            key: '401KMatch',
            label: '401K Match',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.four01KMatch))
        },
        {
            key: '401KAdmin',
            label: '401K Administration',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.four01KAdmin))
        },
        {
            key: 'dotPhysicals',
            label: 'DOT Physicals',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.dotPhysicals))
        },
        {
            key: 'adminFee',
            label: 'Admin Fee',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.adminFee) + '%'
        },
        {
            key: 'recruiting',
            label: 'Recruiting',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.recruiting))
        },
        {
            key: 'fuel',
            label: 'Fuel',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.fuel))
        },
        {
            key: 'maintenance',
            label: 'Maintenance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.maintenance))
        },
        {
            key: 'truckPayments',
            label: 'Truck Payments',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.truckPayments))
        },
        {
            key: 'licenseAndRegistration',
            label: 'License/Registrations',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.licenseAndRegistration))
        },
        {
            key: 'insurance',
            label: 'Insurance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.insurance))
        },
        {
            key: 'vanWashing',
            label: 'Truck Washing',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.vanWashing))
        },
        {
            key: 'vanDecals',
            label: 'Truck Decals',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.vanDecals))       
        },
        {
            key: 'truckRentals',
            label: 'Truck Rentals',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.truckRentals))
        },
        {
            key: 'technology',
            label: 'Technology (Scanners/Tracking)',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.technology))
        },
        {
            key: 'other',
            label: 'Misc. Costs/Other Expenses',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.other))
        },
    ].map((row)=> <PDFTableRow key={row.key} columns={[row.label, row.frequency, row.value]} fontSize={10}/> )
    
    ///////////////////////
    /// PRODUCTIVTY ROWS
    ///////////////////////

    const weeklyDispatches = [0, 1, 2, 3, 4, 5, 6].reduce((prev, dayIndex)=> {
        return prev + validateInteger(budget.budgetProductivity[dayIndex].dispatchesLight)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStandard)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStraight);
    }, 0)

    const yearlyDispatches = weeklyDispatches * 52;

    const lightDispatchesRow = <PDFTableRow key='lightDispatchesRow' columns={['Light', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesLight);} ), '', '']} fontSize={10}/>  
    const standardDispatchesRow = <PDFTableRow key='standardDispatchesRow' columns={['Standard', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesStandard);} ), '', '']} fontSize={10}/>  
    const straightDispatchesRow = <PDFTableRow key='straightDispatchesRow' columns={['Straight', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesStraight);} ), '', '']} fontSize={10}/>
    const totalDispatchesRow = <PDFTableRow key='totalDispatchesRow' columns={['Total', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesLight)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStandard)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStraight);} ), weeklyDispatches.toLocaleString(), yearlyDispatches.toLocaleString()]} fontSize={10}/>
    
    const productivityRows = [lightDispatchesRow, standardDispatchesRow, straightDispatchesRow, totalDispatchesRow];

    ////////////////////////
    /// EXPENSES ROWS 2 DERIVED VALUES
    ////////////////////////
    const expensesRowsDerived = [
        {
            key: 'driverWages',
            label: 'Driver Wages',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDriverWage(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDriverWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDriverWages().toNumber())
        },
        {
            key: 'aoWages',
            label: 'AO Wages',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAoWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAoWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAoWages().toNumber())
        },
        {
            key: 'bcWages', 
            label: 'BC Wages', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyBcWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyBcWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyBcWages().toNumber())
        },
        {
            key: 'adminWages', 
            label: 'Admin Wages', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdminWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminWages().toNumber())
        },
        {
            key: 'driverPto',
            label: 'Driver PTO',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDriverPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyDriverPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDriverPto().toNumber())
        },
        {
            key: 'aoPto',
            label: 'AO PTO',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAoPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAoPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAoPto().toNumber())
        },
        {
            key: 'bcPto', 
            label: 'BC PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyBcPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyBcPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyBcPto().toNumber())
        },
        {
            key: 'adminPto', 
            label: 'Admin PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdminPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminPto().toNumber())
        },
        {
            key: 'uniforms', 
            label: 'Uniforms', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyUniformCost(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyUniformCost().toNumber()),
            yearly: usdFormatter.format(budget.yearlyUniformCost().toNumber())
        },
        {
            key: 'payrollTaxes', 
            label: 'Payroll Taxes', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPayrollTaxExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyPayrollTaxExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPayrollTaxExpense().toNumber())
        },
        {
            key: 'workmansComp', 
            label: 'Workers Comp', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyWorkersCompExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyWorkersCompExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyWorkersCompExpense().toNumber())
        },
        {
            key: 'healthInsurance', 
            label: 'Health Insurance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyHealthInsurance(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyHealthInsurance().toNumber()),
            yearly: usdFormatter.format(budget.yearlyHealthInsurance().toNumber())
        },
        {
            key: 'lifeInsurance', 
            label: 'Life Insurance & Short Term Disability', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLifeInsurance(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyLifeInsurance().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLifeInsurance().toNumber())
        },
        {
            key: 'dentalAndVision', 
            label: 'Dental & Vision', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDentalAndVision(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyDentalAndVision().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDentalAndVision().toNumber())
        },
        {
            key: 'pensionAndProfitSharing', 
            label: 'Pension/Profit Sharing', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPensionAndProfitSharing(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyPensionAndProfitSharing().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPensionAndProfitSharing().toNumber())
        },
        {
            key: 'drugScreens', 
            label: 'Drug Screens', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDrugScreens(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyDrugScreens().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDrugScreens().toNumber())
        },
        {
            key: '401KMatch', 
            label: '401K Match', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.daily401KMatch(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weekly401KMatch().toNumber()),
            yearly: usdFormatter.format(budget.yearly401KMatch().toNumber())
        },
        {
            key: '401KAdmin', 
            label: '401K Administration', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.daily401KAdmin(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weekly401KAdmin().toNumber()),
            yearly: usdFormatter.format(budget.yearly401KAdmin().toNumber())
        },
        {
            key: 'dotPhysicals', 
            label: 'Dot Physicals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDotPhysicalExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDotPhysicalExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDotPhysicalExpense().toNumber())
        },
        {
            key: 'adminFees', 
            label: 'Admin Fees', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyAdminExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminExpense().toNumber())},
        {
            key: 'recruiting', 
            label: 'Recruiting', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyRecruitingExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyRecruitingExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyRecruitingExpense().toNumber())},
        {
            key: 'fuel', 
            label: 'Fuel', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyFuelExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyFuelExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyFuelExpense().toNumber())},
        {
            key: 'maintenance', 
            label: 'Maintenance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyMaintenanceExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyMaintenanceExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyMaintenanceExpense().toNumber())},
        {
            key: 'truckPayments', 
            label: 'Truck Payments', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTruckPaymentExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTruckPaymentExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTruckPaymentExpense().toNumber())},
        {
            key: 'licensesAndRegistration', 
            label: 'Licenses/Registration', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLicenseRegistrationExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyLicenseRegistrationExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLicenseRegistrationExpense().toNumber())},
        {
            key: 'insurance', 
            label: 'Insurance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyInsuranceExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyInsuranceExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyInsuranceExpense().toNumber())},
        {
            key: 'vanWashing', 
            label: 'Truck Washing', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVanWashingExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyVanWashingExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVanWashingExpense().toNumber())
        },
        {
            key: 'vanDecals', 
            label: 'Truck Decals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVanDecals(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyVanDecals().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVanDecals().toNumber())
        },
        {
            key: 'truckRentals', 
            label: 'Truck Rentals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTruckRentalExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTruckRentalExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTruckRentalExpense().toNumber())},
        {
            key: 'techCharges', 
            label: 'Technology Charges (Scanners/Tracking)', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTechnologyExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTechnologyExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTechnologyExpense().toNumber())},
        {
            key: 'miscExpenses', 
            label: 'Misc. Costs/Other Expenses', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyOtherExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyOtherExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyOtherExpense().toNumber())},
        {
            key: 'totalExpenses', 
            label: 'Total Expenses', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.totalDailyExpenses(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.totalWeeklyExpenses().toNumber()),
            yearly: usdFormatter.format(budget.totalYearlyExpenses().toNumber())},
        {
            key: 'costPerDispatch', 
            label: 'Cost Per Dispatch', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyExpensesPerDispatch(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyExpensesPerDispatch().toNumber()),
            yearly: usdFormatter.format(budget.yearlyExpensesPerDispatch().toNumber())
        },
    ].map((row) => {
        return (
            <PDFTableRow key={row.key} columns={[row.label, ...row.values, row.weekly, row.yearly]} fontSize={10}/> 
        )
    });

    return (
    <Document> 
        <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}} orientation='landscape'>
            <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:15}}>
                {/* <Image src={logo} style={{width:150}}/> */}
                <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: 8, fontSize: 28}}>
                    <Text>Projected Budget</Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: 12}}>
                    <Text>{budget.title}</Text>
            </View>

            {/* Expenses Table */}
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Expenses' headers={['Expense', 'Frequency', 'Amount']} rows={expensesRows} fontSize={10}/>
            </View>

            {/* Productivity Table */}
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Productivity' headers={['', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly']} rows={productivityRows} fontSize={10}/>
            </View>

            {/* Expenses Derived Table */}
            <View wrap={true} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Expenses' headers={['', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly']} rows={expensesRowsDerived} fontSize={10}/>
            </View>
 
        </Page>
    </Document>
    )
}