/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import {
  Button,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import QuickTable from "../../../components/QuickTable";
import AlertModal from "../../../components/AlertModals/AlertModal";
import { useEffect, useState } from "react";
import KeyValueRow from "../../../components/KeyValueRow";
import {
  DirectDepositDocuments,
  OnboardingDocumentsReadOnly,
  RejectedOnboardingDocumentsReadOnly,
  downloadBase64,
  taxDocumentDictionary,
} from "../../../tools";
import CustomButton from "../../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faHistory,
  faMagnifyingGlass,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/free-regular-svg-icons";
import EDDocumentPreview from "./EDDocumentPreview";
import OldAttachmentPreview from "./OldAttachmentPreview";
import { ApiRequest } from "../../../ApiManager.tsx";
import EdDocumentItem from "./EdDocumentItem.js";
import TextAreaControl from "../../../components/TextAreaControl.js";

export default function DocumentRequestDetails({
  selectedEmployee,
  documentRequest,
  handleSetEDDocumentIncomplete,
}) {
  const [docToSendBack, setDocToSendBack] = useState(undefined);
  const [showSendBackModal, setShowSendBackModal] = useState(false);
  const [isSendingBack, setIsSendingBack] = useState(false);
  const [revisionMessage, setRevisionMessage] = useState("");
  const [isDownloading, setIsDownloading] = useState(undefined);
  const [docToPreview, setDocToPreview] = useState(undefined);
  const [showPreview, setShowPreview] = useState(false);
  const [showSendInvitation, setShowSendInvitation] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(undefined);
  const [showAttachment, setShowAttachment] = useState(false);
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [dlIsPreviewing, setDlIsPreviewing] = useState(false);
  const [ssnIsPreviewing, setSsnIsPreviewing] = useState(false);
  const [dlIsDownloading, setDlIsDownloading] = useState(false);
  const [ssnIsDownloading, setSsnIsDownloading] = useState(false);
  const [showRejectedVersionsModal, setShowRejectedVersionsModal] =useState(false);
  const [selectedRejectedDocuments, setSelectedRejectedDocuments] = useState([]);

  function handleSendBackDocument() {
    if (docToSendBack === "ssn" || docToSendBack === "dl") {
      new ApiRequest("hr", "sendBackDlOrSsn", setIsSendingBack, () => {
        if (docToSendBack === "dl") {
          documentRequest.dlDataIdentifier = null;
        } else {
          documentRequest.ssnDataIdentifier = null;
        }
        setRevisionMessage("");
        setShowSendBackModal(false);
      })
        .withData({
          documentRequestIdentifier: documentRequest.uid,
          documentType: docToSendBack,
          message: revisionMessage,
        })
        .send();
    } else {
      new ApiRequest("hr", "sendBackEdDocument", setIsSendingBack, () => {
        handleSetEDDocumentIncomplete(documentRequest.uid, docToSendBack);
        setRevisionMessage("");
        setShowSendBackModal(false);
      })
        .withData({
          documentRequestIdentifier: documentRequest.uid,
          documentType: docToSendBack,
          message: revisionMessage,
        })
        .send();
    }
  }

  function handleDownloadDocument(documentType) {
    if (documentRequest.documents[documentType].base64) {
      downloadBase64(
        documentRequest.documents[documentType].base64,
        `${selectedEmployee.firstName} ${selectedEmployee.lastName} ${OnboardingDocumentsReadOnly[documentType]}`
      );
    } else {
      new ApiRequest(
        "hr",
        "downloadEdDocument",
        setIsDownloading,
        (response) => {
          documentRequest.documents[documentType].base64 = response.document;
          downloadBase64(
            response.document,
            `${selectedEmployee.firstName} ${selectedEmployee.lastName} ${OnboardingDocumentsReadOnly[documentType]}`
          );
        }
      )
        .withData({
          uid: documentRequest.documents[documentType].uid,
          documentType: documentRequest.documents[documentType].archived
            ? "archivedDocument"
            : documentType,
        })
        .withNoAlertOnSuccess()
        .send();
    }
  }

  function handlePreviewDocument(documentType) {
    if (documentRequest.documents[documentType].base64) {
      setDocToPreview(documentRequest.documents[documentType]);
      setShowPreview(true);
    } else {
      new ApiRequest(
        "hr",
        "downloadEdDocument",
        setIsDownloading,
        (response) => {
          documentRequest.documents[documentType].base64 = response.document;
          setDocToPreview(documentRequest.documents[documentType]);
          setShowPreview(true);
        }
      )
        .withData({
          uid: documentRequest.documents[documentType].uid,
          documentType: documentRequest.documents[documentType].archived
            ? "archivedDocument"
            : documentType,
        })
        .withNoAlertOnSuccess()
        .send();
    }
  }

  function handleDownloadSsnOrDl(documentType, uid) {
    const type =
      documentType === "ssn" ? "Social Security Card" : "Driver's License";
    new ApiRequest(
      "hr",
      "downloadDlOrSsn",
      documentType === "ssn" ? setSsnIsDownloading : setDlIsDownloading,
      (response) => {
        downloadBase64(
          response.base64,
          `${selectedEmployee.firstName} ${selectedEmployee.lastName} ${type}`
        );
      }
    )
      .withUid(uid)
      .withNoAlertOnSuccess()
      .send();
  }

  function handlePreviewDlSsn(documentType) {
    const documentIdentifier =
      documentType === "ssn"
        ? documentRequest.ssnDataIdentifier
        : documentRequest.dlDataIdentifier;
    new ApiRequest(
      "hr",
      "downloadDlOrSsn",
      documentType === "ssn" ? setSsnIsPreviewing : setDlIsPreviewing,
      (response) => {
        const title =
          documentType === "dl"
            ? `${selectedEmployee.firstName} ${selectedEmployee.lastName} Driver's License`
            : `${selectedEmployee.firstName} ${selectedEmployee.lastName} Social Security Card`;
        setSelectedAttachment({ base64: response.base64, title });
        setShowAttachment(true);
      }
    )
      .withUid(documentIdentifier)
      .withNoAlertOnSuccess()
      .send();
  }

  function handleSendInvitation() {
    new ApiRequest(
      "hr",
      "sendDocumentRequestInvitation",
      setIsSendingInvitation,
      () => setShowSendInvitation(false)
    )
      .withUid(documentRequest.uid)
      .send();
  }

  function handleShowRejectedVersionsModal(type){
    setSelectedRejectedDocuments(
      documentRequest.rejectedDocuments[type]
    );
    setShowRejectedVersionsModal(true);
  }

  const sortedDocuments = [
    "offerLetter",
    "i9",
    "w4",
    ...Object.keys(taxDocumentDictionary),
    ...Object.keys(DirectDepositDocuments),
    "f8850",
  ].filter((d) => Object.keys(documentRequest.documents).includes(d));
  const edDocumentElements = sortedDocuments
    .filter((dType) => !documentRequest.documents[dType].archived)
    .map((documentType) => {
      return (
        <EdDocumentItem
          key={documentType}
          documentType={documentType}
          documentRequest={documentRequest}
          selectedEmployee={selectedEmployee}
          handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}
          setRevisionMessage={setRevisionMessage}
          setShowSendBackModal={setShowSendBackModal}
          setDocToPreview={setDocToPreview}
          revisionMessage={revisionMessage}
          setShowPreview={setShowPreview}
          setDocToSendBack={setDocToSendBack}
          handleShowRejectedVersionsModal={handleShowRejectedVersionsModal}
        />
      );
    });

  const archivedElements = sortedDocuments
    .filter((dType) => documentRequest.documents[dType].archived)
    .map((documentType) => {
      const doc = documentRequest.documents[documentType];
      return (
        <tr key={documentType}>
          <td>{OnboardingDocumentsReadOnly[documentType]}</td>
          <td>
            <div
              style={{ display: "flex", gap: 6, justifyContent: "flex-end" }}
            >
              {(doc.employeeCompleted || doc.archived) && (
                <>
                  <CustomButton
                    title="Preview"
                    style={{ padding: "0px 6px 0px 6px" }}
                    label={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                    isLoading={documentType == isDownloading}
                    onClick={() => handlePreviewDocument(documentType)}
                  />
                  <CustomButton
                    title="Download"
                    style={{ padding: "0px 6px 0px 6px" }}
                    label={<FontAwesomeIcon icon={faDownload} />}
                    isLoading={documentType == isDownloading}
                    onClick={() => handleDownloadDocument(documentType)}
                  />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });

  const dlAndSsnElements = (
    <>
      <tr>
        <td>Driver's License</td>
        <td
          style={{
            color: documentRequest.dlDataIdentifier
              ? "var(--bs-success)"
              : "red",
          }}
        >
          {documentRequest.dlDataIdentifier
            ? "Completed"
            : "Awaiting Completion"}
        </td>
        <td>
          <div style={{ display: "flex", gap: 6, justifyContent: "flex-end" }}>
            {documentRequest.dlDataIdentifier ? (
              <>
                <CustomButton
                  title="Preview"
                  style={{ padding: "0px 6px 0px 6px" }}
                  label={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                  isLoading={dlIsPreviewing}
                  onClick={() => {
                    handlePreviewDlSsn("dl");
                  }}
                />
                <CustomButton
                  title="Download"
                  style={{ padding: "0px 6px 0px 6px" }}
                  label={<FontAwesomeIcon icon={faDownload} />}
                  isLoading={dlIsDownloading}
                  onClick={() =>
                    handleDownloadSsnOrDl(
                      "dl",
                      documentRequest.dlDataIdentifier
                    )
                  }
                />
                <CustomButton
                  title="Rejection History"
                  style={{ padding: "0px 6px" }}
                  disabled={
                    !documentRequest.rejectedDocuments['dl']
                  }
                  label={<FontAwesomeIcon icon={faHistory} />}
                  onClick={() => {
                    handleShowRejectedVersionsModal('dl');
                  }}
                />
                {documentRequest.adminDismissed ? (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover>
                        <Popover.Body>
                          This document cannot be rejected as this document
                          request has been dismissed by an administrator.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span>
                      <Button
                        variant="outline-danger"
                        title="Request Revision"
                        style={{ padding: "0px 6px", whiteSpace: "nowrap" }}
                        disabled
                      >
                        <FontAwesomeIcon icon={faThumbsDown} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                ) : (
                  <Button
                    variant="outline-danger"
                    title="Request Revision"
                    style={{ padding: "0px 6px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      setDocToSendBack("dl");
                      setShowSendBackModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faThumbsDown} />
                  </Button>
                )}
              </>
            ) : (
              documentRequest?.rejectedDocuments?.['dl']
                ?.length && (
                <CustomButton
                  title="Rejection History"
                  style={{ padding: "0px 6px" }}
                  disabled={
                    !documentRequest.rejectedDocuments['dl']
                  }
                  label={<FontAwesomeIcon icon={faHistory} />}
                  onClick={() => {
                    handleShowRejectedVersionsModal('dl');
                  }}
                />
              )
            )}
          </div>
        </td>
      </tr>
      <tr>
        <td>Social Security Card</td>
        <td
          style={{
            color: documentRequest.ssnDataIdentifier
              ? "var(--bs-success)"
              : "red",
          }}
        >
          {documentRequest.ssnDataIdentifier
            ? "Completed"
            : "Awaiting Completion"}
        </td>
        <td>
          <div style={{ display: "flex", gap: 6, justifyContent: "flex-end" }}>
            {documentRequest.ssnDataIdentifier ? (
              <>
                <CustomButton
                  title="Preview"
                  style={{ padding: "0px 6px 0px 6px" }}
                  label={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                  isLoading={ssnIsPreviewing}
                  onClick={() => {
                    handlePreviewDlSsn("ssn");
                  }}
                />
                <CustomButton
                  title="Download"
                  style={{ padding: "0px 6px 0px 6px" }}
                  label={<FontAwesomeIcon icon={faDownload} />}
                  isLoading={ssnIsDownloading}
                  onClick={() =>
                    handleDownloadSsnOrDl(
                      "ssn",
                      documentRequest.ssnDataIdentifier
                    )
                  }
                />
                <CustomButton
                  title="Rejection History"
                  style={{ padding: "0px 6px" }}
                  disabled={
                    !documentRequest.rejectedDocuments['ssn']
                  }
                  label={<FontAwesomeIcon icon={faHistory} />}
                  onClick={() => {
                    handleShowRejectedVersionsModal('ssn');
                  }}
                />
                {documentRequest.adminDismissed ? (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover>
                        <Popover.Body>
                          This document cannot be rejected as this document
                          request has been dismissed by an administrator.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span>
                      <Button
                        variant="outline-danger"
                        title="Request Revision"
                        style={{ padding: "0px 6px", whiteSpace: "nowrap" }}
                        disabled
                      >
                        <FontAwesomeIcon icon={faThumbsDown} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                ) : (
                  <Button
                    variant="outline-danger"
                    title="Request Revision"
                    style={{ padding: "0px 6px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      setDocToSendBack("ssn");
                      setShowSendBackModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faThumbsDown} />
                  </Button>
                )}
              </>
            ): (
              documentRequest?.rejectedDocuments?.['ssn']
                ?.length && (
                <CustomButton
                  title="Rejection History"
                  style={{ padding: "0px 6px" }}
                  disabled={
                    !documentRequest.rejectedDocuments['ssn']
                  }
                  label={<FontAwesomeIcon icon={faHistory} />}
                  onClick={() => {
                    handleShowRejectedVersionsModal('ssn');
                  }}
                />
              )
            )}
          </div>
        </td>
      </tr>
    </>
  );

  const activityElements = documentRequest.activity.map((item) => {
    return (
      <KeyValueRow
        isListItem
        key={item.uid}
        title={item.isEmployer ? "Employer" : "Employee"}
        value={moment(item.dateTime).format("MMM D, YYYY [at] h:mm A")}
      />
    );
  });

  const oldAttachmentsElements = documentRequest.oldAttachments.map((oa) => {
    return (
      <ListGroup.Item
        key={oa.uid}
        action
        onClick={() => {
          setSelectedAttachment(oa);
          setShowAttachment(true);
        }}
      >
        {oa.title}
      </ListGroup.Item>
    );
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Document Request</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: 12,
            gap: 12,
          }}
        >
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowSendInvitation(true);
            }}
          >
            Resend Employee Invitation
          </Button>
          <a
            target="_blank"
            className="btn btn-outline-primary"
            href={`https://mytacticalrecruiting.com/employee-onboarding/${documentRequest.employerToken}`}
          >
            Access Document Request as Employer
          </a>
        </div>
        {edDocumentElements.length > 0 && (
          <QuickTable
            title="Documents"
            headers={[
              "Title",
              "Employee Completed",
              "Employer Completed",
              "Actions",
            ]}
            rows={edDocumentElements}
            widths={[null, null, null, 1]}
            size="sm"
          />
        )}
        {"i9" in documentRequest.documents && (
          <QuickTable
            title={`Driver's License and Social Security Card`}
            headers={["Title", "Employee Completed", "Actions"]}
            rows={dlAndSsnElements}
            widths={[null, null, 1]}
            size="sm"
          />
        )}
        {archivedElements.length > 0 && (
          <QuickTable
            title="Archived Documents"
            headers={["Document Type", "Actions"]}
            rows={archivedElements}
            widths={[null, 1]}
            size="sm"
          />
        )}
        {oldAttachmentsElements.length > 0 && (
          <>
            <h5>Archived Attachments</h5>
            <ListGroup style={{ marginBottom: 12 }}>
              {oldAttachmentsElements}
            </ListGroup>
          </>
        )}
        <h5>Request View Activity</h5>
        <ListGroup>
          {activityElements.length > 0 ? (
            activityElements
          ) : (
            <ListGroup.Item>None</ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal
        centered
        show={showSendInvitation}
        onHide={() => {
          setShowSendInvitation(false);
        }}
      >
        <AlertModal
          isLoading={isSendingInvitation}
          title="Resend Employee Invitation?"
          message="The employee will receive an email with a link to view/complete this document request"
          buttonLabel="Resend Invitation"
          callBack={handleSendInvitation}
        />
      </Modal>
      <Modal
        show={showSendBackModal}
        onHide={() => {
          setShowSendBackModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Document Back to Employee to Revise?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Include a message below for the employee with instructions on what
            needs revised.
          </span>
          <Form.Control
            value={revisionMessage}
            onChange={(event) => {
              setRevisionMessage(event.target.value);
            }}
            as="textarea"
            maxLength={500}
            rows={5}
          />
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            disabled={!revisionMessage}
            label="Request Revision"
            isLoading={isSendingBack}
            onClick={handleSendBackDocument}
          />
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPreview}
        onHide={() => {
          setShowPreview(false);
        }}
        fullscreen
      >
        <EDDocumentPreview document={docToPreview} />
      </Modal>
      <Modal
        show={showAttachment}
        onHide={() => {
          setShowAttachment(false);
        }}
        fullscreen
      >
        <OldAttachmentPreview document={selectedAttachment} />
      </Modal>
      <Modal
        centered
        show={showRejectedVersionsModal}
        onHide={() => setShowRejectedVersionsModal(false)}
      >
        <RejectedDocumentsModal rejectedDocuments={selectedRejectedDocuments}/>
      </Modal>
    </>
  );
}

function RejectedDocumentsModal({ rejectedDocuments}) {
  const documentType = rejectedDocuments[0].documentType;
  const [document, setDocument] = useState();
  const [isPreviewing, setIsPreviewing] = useState();

  async function previewDocument(rejectedDocument) {
    setIsPreviewing(rejectedDocument.uid)
    const encodedData = {
      documentType: documentType,
      uid: rejectedDocument.uid,
      dataIdentifier: rejectedDocument?.dataIdentifier,
      documentRejectionIdentifier: rejectedDocument?.documentRejectionIdentifier
    }

    new ApiRequest("hr", "getRejectedDocumentRequestDocument", () => {}, (response) => {
      response.documentRequestDocument.documentRejection =
        rejectedDocument.documentRejection;
      response.documentRequestDocument.documentType = documentType;
      if (response.documentRequestDocument.employerSubmission) {
        response.documentRequestDocument.base64 =
          response.documentRequestDocument.employerSubmission;
      } else if (response.documentRequestDocument.employeeSubmission) {
        response.documentRequestDocument.base64 =
          response.documentRequestDocument.employeeSubmission;
      }
      setDocument(response.documentRequestDocument);
      setIsPreviewing(undefined)
    }, () => {setIsPreviewing(undefined)})
      .withData(encodedData)
      .withNoAlertOnSuccess()
      .send();
  }

  const rows = rejectedDocuments.map((rejectedDocument) => {
    return (
      <tr key={rejectedDocument.uid}>
        <td>
          {moment(rejectedDocument.documentRejection.dateTime).format(
            "lll"
          )}
        </td>
        <td>{`${rejectedDocument.documentRejection.firstName} ${rejectedDocument.documentRejection.lastName}`}</td>
        <td>
          <CustomButton
            title="Preview"
            style={{ padding: "0px 6px 0px 6px" }}
            label={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            isLoading={isPreviewing === rejectedDocument.uid}
            onClick={() => previewDocument(rejectedDocument)}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          Rejected Versions for {RejectedOnboardingDocumentsReadOnly[documentType]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QuickTable
          headers={["Date Rejected", "Rejected By", "View"]}
          rows={rows}
          widths={[null, null, null]}
          size="sm"
        />
      </Modal.Body>
      <Modal
        fullscreen
        show={Boolean(document)}
        onHide={() => setDocument(undefined)}
      >
        {Boolean(document) && (
          <RejectedDocumentPreview rejectedDocument={document} />
        )}
      </Modal>
    </>
  );
}

function RejectedDocumentPreview({ rejectedDocument }) {
  const [base64, _setBase64] = useState(rejectedDocument.base64);
  const [url, setUrl] = useState("");

  useEffect(() => {
    const fetchAndSetUrl = async () => {
      if (base64) {
        try {
          const fetchResponse = await fetch(base64);
          const blob = await fetchResponse.blob();
          setUrl(window.URL.createObjectURL(blob));
        } catch (error) {
          console.error("Error fetching or creating URL:", error);
        }
      }
    };

    fetchAndSetUrl();
  }, [base64]);

  const renderDocument = () => {
    if (base64.split(",", 1)[0].includes("pdf")) {
      return (
        <div
          style={{
            backgroundColor: "#f4f4f5",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <iframe
            src={url}
            width="100%"
            height="100%"
            title="Embedded Page"
            style={{ border: "none", overflow: "hidden" }}
          />
        </div>
      );
    } else if (base64) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            backgroundColor: "#f4f4f5",
            overflow: "hidden",
          }}
        >
          <img
            src={base64}
            alt={document.name}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }
    return null;
  };

  const failedToPreviewDocument = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          padding: "16px",
          color: "var(--bs-danger)",
        }}
      >
        <FontAwesomeIcon
          icon={faXmarkCircle}
          style={{ marginRight: 8, height: 24, width: 24 }}
        />
        <span style={{ fontWeight: 500 }}>Failed to preview document</span>
      </div>
    );
  };

  return (
    <>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Previewing Rejected Document</Modal.Title>
          <p style={{ margin: 0, fontSize: "0.9rem", opacity: .7 }}>
            {`${
              RejectedOnboardingDocumentsReadOnly[rejectedDocument.documentType]
            } - Rejected by ${rejectedDocument.documentRejection.firstName} ${rejectedDocument.documentRejection.lastName} - ${moment(
              rejectedDocument.documentRejection.dateTime
            ).format("lll")}`}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: 8 }}>
          <TextAreaControl
            rows={5}
            label={"Revision Message"}
            value={rejectedDocument.documentRejection.rejectionMessage}
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
          }}
        >
          {renderDocument() ?? failedToPreviewDocument()}
        </div>
      </Modal.Body>
    </>
  );
}
