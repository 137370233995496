import { faClipboardQuestion, faCouch, faFile, faFileExcel, faFileImage, faFilePdf, faFileVideo, faFileWord, faGraduationCap, faPersonCircleQuestion, faPersonCircleXmark, faPersonRunning, faPhone, faPlaneDeparture, faTruck, faUserTie } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Col from 'react-bootstrap/Col';
import { pdf } from "@react-pdf/renderer"
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const defaultOfferLetter = `[CompanyName]
[CreatedDate]
re: Employment offer
[EmployeeName]

Dear [EmployeeName],

[CompanyName] is pleased to offer you employment as a [EmployeeType] small package delivery driver. Please note packages delivered by you and all FedEx delivery drivers may range in size from less than 1 lb to over 150 lbs.

Your start date will be [StartDate]. You will report to your immediate supervisor [SupervisorName] at [TerminalAddress] for training by [StartTime] on the date listed above.

Your starting pay will be [Pay]. You may qualify for additional pay if you exceed [StopBonusThresholds] every day paid out at [StopBonusAmounts] per stop for each stop over your threshold.

You are expected to be available [ExpectedAvailability], and your start time for each scheduled work day will be [DailyStartTime]. Your end time for each day worked will be determined by the package volume on your delivery truck that day, so end times will vary from day to day and month to month, especially during holidays. A sixth day of work per week may be required of you during the holiday season in November and December. Scheduled days are subject to change per the needs of [CompanyName] and FedEx.

You will receive an Employee Handbook with all of the [CompanyName] employment policies. It is your responsibility to read the entire Employee Handbook, understand all the policies in it, and abide by these policies while employed at [CompanyName]. Failure to follow company policies will lead to progressive disciplinary action, up to and including termination. You will be required to acknowledge the Employee Handbook when the Handbook is given to you.

You may qualify for the following benefits: [Benefits]

Note that [CompanyName] is an At-Will employer and you are an At-Will employee, therefore [CompanyName] or you may end your employment with [CompanyName] at any time for any reason. This employment letter does not constitute a contract for employment but is merely an informational document for you to use as a new employee of [CompanyName].

Sincerely,
[AOName]
   
Authorized Officer | [AOPhoneNumber] | [AOEmail]`;

export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function formatPhoneNumber(str) {
    return '(' + str.slice(0, 3) + ') ' + str.slice(3, 6) + '-' + str.slice(6);
}

export function formatSSN(str) {
    return str.slice(0, 3) + '-' + str.slice(3, 5) + '-' + str.slice(5);
}

export function formatTime(time, format) {
    return moment(moment().format('YYYY-MM-DD') + ' ' + time).format(format);
}

export function parseAvailability(str) {
    const array = [];
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek) => {
        array.push(str.includes(dayOfWeek));
    });
    return array;
}

export function encodeAvailability(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let arr = [];
    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            arr.push(daysOfTheWeek[i]);
        }
    }
    return arr.join(',');
}

export function availabilityToString(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function availabilityToStringShort(availability) {
    const daysOfTheWeek = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function availabilityToBinaryString(availability) {
    let str = '';
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek) => {
        str += (availability.includes(dayOfWeek) ? '1' : '0');
    });
    return str;
}

export function availabilityFromBinaryString(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability.toString().charAt(i) == '1') {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function packageAddress(object) {
    object.address = {
        addressIdentifier: object.addressIdentifier,
        thoroughfare: object.thoroughfare, 
        premise: object.premise, 
        locality: object.locality, 
        administrativeArea: object.administrativeArea, 
        postalCode: object.postalCode
    }
}

export function addressComponentsToString(thoroughfare, premise, locality, administrativeArea, postalCode) {
    return addressToString({thoroughfare, premise, locality, administrativeArea, postalCode});
}

export function addressToString(address) {
    if (address) {
        let str = '';

        if (address.thoroughfare) {
            str += address.thoroughfare;
        }
        if (address.premise) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.premise;
        }
        if (address.locality) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.locality;
        }
        if (address.administrativeArea) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.administrativeArea;
        }
        if (address.postalCode) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.postalCode
        }
        return str;
    } else {
        return '';
    }
}


export const bwcCodeDictionary = {
    '7231': 'Driver',
    '8810': 'Admin', 
    '7219': 'Mechanic', 
}

export const payTypeDictionary = {
    ph: 'Per Hour',
    pd: 'Per Day',
    py: 'Per Year',
    pm: 'Per Mile',
    ps: 'Per Stop'
}

export const stateDictionary = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

export const documentDictionary = {
    'W4': 'W4',
    'IT4': 'Ohio State Tax', 
    'Direct Deposit Form': 'Direct Deposit Form', 
    'MDD': 'Multiple Direct Deposit', 
    'WH-4': 'Indiana State Tax',
    'Onboarding Packet': 'Onboarding Packet'
}

export const ptoTransactionTypeDictionary = {
    'manual': 'Manually Entered',
    'scheduled': 'PTO',
    'accounted': 'PTO (not in balance)',
    'accrued': 'Automatic Accrual',
    'payroll': 'Payroll',
}

export const terminationTypeDictionary = {
    'resignation': 'Resignation',
    'abandonment': 'Abandonment',
    'retirement': 'Retirement',
    'terminateForCause': 'Terminate for Cause',
    'layoff': 'Layoff',
    'endOfContract': 'End of Contract',
}

export function getUnitsWorked(entry) {
    switch(entry.payType) {
        case 'ph':
            return entry.hoursWorked;
        case 'pm':
            return entry.miles ? entry.miles : 0;
        case 'ps':
            return entry.stops ? entry.stops : 0;
        default: //pd and py
            return entry.daysWorked;
    }
}

export function getUnitsWorkedTypeString(payType) {
    if (payType === 'ph') {
        return 'Hours Worked';
    } else if (payType === 'pm') {
        return 'Number of Miles';
    } else if (payType === 'ps') {
        return 'Number of Stops';
    } else { //pd and py
        return 'Days Worked';
    }
}

export function getHolidayUnitsTypeString(payType) {
    if (payType === 'ph') {
        return 'Paid Holiday Hours';
    } else if (payType === 'pm') {
        return 'Paid Holiday Miles';
    } else if (payType === 'ps') {
        return 'Paid Holiday Stops';
    } else if(payType === 'pd'){ //pd and py
        return 'Paid Holiday Days';
    } else {
        return 'Holiday Flat Amount'
    }
}

export const employeeTypeDictionary = {
    0: 'Full-Time',
    1: 'Part-Time'
}

export const ptoAccrualTypeDictionary = {
    0: 'Per 40 Hours',
    1: 'Per Pay Period'
}

export const scheduleTypeDictionary = {
    route: {
        label: 'Route',
        icon: faTruck,
        color: '#ffa496'
    },
    driverGeneratedRoute: {
        label: 'Driver Generated Route',
        icon: faTruck,
        color: '#ffa496'
    },
    managerOnDuty: {
        label: 'Manager On Duty',
        icon: faUserTie,
        color: '#73ffcc'
    },
    paidTraining: {
        label: 'Paid Training',
        icon: faGraduationCap,
        color: '#96c7ff'
    },
    auxiliaryWorker:{
        label: 'Auxiliary Worker',
        icon: faPersonRunning,
        color: '#00FFFF'
    }, 
    callOff: {
        label: 'Call Off',
        icon: faPhone,
        color: '#bc96ff'
    }, 
    dayOff: {
        label: 'Day Off',
        icon: faCouch,
        color: '#8affa5'
    }, 
    pto: {
        label: 'Paid Time Off',
        icon: faPlaneDeparture,
        color: '#b2ff96',
    },
    requestOff: {
        label: 'Request Off',
        icon: faClipboardQuestion,
        color: '#eded77',
    },
    rejectedRequest: {
        label: 'Rejected Request',
        icon: faPersonCircleXmark,
        color: 'lightgray',
    },
    noShow: {
        label: 'No-Show',
        icon: faPersonCircleQuestion,
        color: '#a992b0'
    },
}

export const titleDictionary = {
    Driver: {
        color: 'lightgray',
    },
    'AVP Driver': {
        color: 'lightgray'
    },
    'Jumper': {
        color: 'lightgray'
    },
    BC: {
        color: 'lightblue',
    },
    AO: {
        color: 'orange'
    },
    Admin: {
        color: 'lime'
    }
}
export const packetIdentifierDictionary = {
    'w2hf6NLm5BxHtcokpP6xgD': 'Ohio',
    '7CdeqzA5dwXvkEPmpCyzEe': 'Indiana',
    'MKdJtUhFRfKc7NJpyWD5AR': 'Alabama',
    'hLD99dwD6GmAiZSZRZPxCS': 'Kentucky',
    'ahWXWKMLeLWcvn4t4RGMxS': 'Louisiana',
    'vuXRFyuBmUMe75zhS8hS9d': 'No Income Tax',
}

export function durationToString(durationInMinutes) {

    let totalMinutes = durationInMinutes;
    
    let days = 0;
    let hours = 0;
    let minutes = 0;

    while (totalMinutes >= 1440) {
        days += 1;
        totalMinutes -= 1440;
    }
    while (totalMinutes >= 60) {
        hours += 1;
        totalMinutes -= 60;
    }
    minutes = totalMinutes;
    
    const arr = [];
    if (days) {
        arr.push(days > 1 ? days + ' days' : '1 day');
    }
    if (hours) {
        arr.push(hours > 1 ? hours + ' hours' : '1 hour');
    }
    if (minutes) {
        arr.push(minutes > 1 ? minutes + ' minutes' : '1 minute');
    }

    const str = arr.join(', ');

    return str;
}

export function formatPastDateTime(dateTime) {
    if (moment(dateTime).isSame(moment(), 'year')) {
        if (moment(dateTime).isSame(moment(), 'week')) {
            if (moment(dateTime).isSame(moment(), 'day')) {
                return moment(dateTime).format('h:mm A');
            } else {
                return moment(dateTime).format('ddd [at] h:mm A');
            }
        } else {
            return moment(dateTime).format('MMM D [at] h:mm A');
        }
    } else {
        return moment(dateTime).format('MMM D, YYYY [at] h:mm A');
    }
}

export function formatDateRange(startDate, endDate) {
    if (!startDate && !endDate) {
        return 'All Time';
    } else if (!startDate) {
        return 'Before ' + moment(endDate).format('MMM D, YYYY');
    } else if (!endDate) {
        return 'After ' + moment(startDate).format('MMM D, YYYY');
    } else {
        if (moment(startDate).isSame(moment(endDate), 'year')) {
            if (moment(startDate).isSame(moment(endDate), 'month')) {
                if (moment(startDate).isSame(moment(endDate), 'day')) {
                    return moment(startDate).format('MMM D, YYYY');
                } else {
                    return `${moment(startDate).format('MMM D')}-${moment(endDate).format('D, YYYY')}`;
                }
            } else {
                return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D, YYYY')}`;
            }
        } else {
            return `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`;
        }
    }
}

export function calculateHourlyWage(payType, payRate) {
    if (payType === 'pd') {
        return payRate / 8.0;
    } else if (payType === 'py') {
        return ((payRate / 52.0) / 40.0) / 8.0;
    }
}

export function validateDecimal(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateDecimalFixed(value, decimalPlaces) {
    return parseFloat(validateDecimal(value).toFixed(decimalPlaces));
}

export function validateInteger(value) {
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}



export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getStartOfWeek() {
    return moment().day() === 6 ? moment() : moment().startOf('week').subtract(1, 'days');
}

export function wrapElementInCol(element, breakpoints) {
    return (
        <Col style={{marginBottom: 8}} xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} xl={breakpoints.xl} xxl={breakpoints.xxl}>
            {element}
        </Col>
    )
}

export function dateRangeToString(startDate, endDate) {
    if (!startDate && !endDate) {
        return 'All Time';
    } else if (!startDate) {
        return 'Before ' + moment(endDate).format('MMM D, YYYY');
    } else if (!endDate) {
        return 'After ' + moment(startDate).format('MMM D, YYYY');
    } else {
        return moment(startDate).format('MMM D, YYYY') + ' - ' + moment(endDate).format('MMM D, YYYY');
    }
}

export function dateIsInRange(date, startDate, endDate) {
    if (!startDate && !endDate) {
        return true;
    } else if (!startDate) {
        return moment(endDate).diff(moment(date), 'days') >= 0;
    } else if (!endDate) {
        return moment(date).diff(moment(startDate), 'days') >= 0;
    } else {
        return moment(date).isBetween(moment(startDate), moment(endDate), 'days', '[]');
    }
}

export function validatePayRollEntryData(entry) {
    const newEntry = structuredClone(entry);
    newEntry.payRate = validateDecimal(newEntry.payRate);
    newEntry.daysWorked = validateInteger(newEntry.daysWorked);
    newEntry.hoursWorked = validateDecimal(newEntry.hoursWorked);
    newEntry.pto = validateInteger(newEntry.pto);
    newEntry.holidays = validateInteger(newEntry.holidays);
    newEntry.hourlyWage = validateDecimal(newEntry.hourlyWage);
    newEntry.childSupport = validateDecimal(newEntry.childSupport);
    newEntry.medical = validateDecimal(newEntry.medical);
    newEntry.dental = validateDecimal(newEntry.dental);
    newEntry.vision = validateDecimal(newEntry.vision);
    return newEntry;
}

export function getFileIcon(documentType) {
    switch (documentType) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'heic':
        case 'heic':
            return faFileImage;
        case 'pdf':
            return faFilePdf;
        case 'doc':
        case 'docx':
        case 'odt':
        case 'rtf':
        case 'txt':
        case 'tex':
        case 'wpd':
            return faFileWord;
        case 'xlsx':
        case 'xls':
        case 'xlsm':
        case 'csv':
        case 'tsv':
        case 'xml':
            return faFileExcel;
        case 'mp4':
        case 'avi':
        case 'wmv':
        case 'mov':
        case 'flv':
        case 'avchd':
        case 'mpeg':
            return faFileVideo;
        default:
            return faFile;
    }
}

export function sortTimes(a, b) {
    const aNum = parseInt(a.slice(0, 2) + a.slice(3, 5));
    const bNum = parseInt(b.slice(0, 2) + b.slice(3, 5));
    return aNum < bNum ? -1 : aNum > bNum ? 1 : 0;
}

export function getTimeOnDayAsMoment(time, date) {
    return moment(moment(date).format('YYYY-MM-DD') + ' ' + time);
}


let fakeUID = 0;

export function getFakeUID() {
    fakeUID--;
    return fakeUID;
}



//ED Document Stuff \/\/\/\/\/\/


export const defaultOfferLetterTitles = {
    'Driver': 'defaultOfferLetterForDriver',
    'AVP Driver': 'defaultOfferLetterForAvpDriver',
    'Jumper': 'defaultOfferLetterForJumper',
    'BC': 'defaultOfferLetterForBc',
    'Admin': 'defaultOfferLetterForAdmin'
}

export const OnboardingDocuments = {
    'w4': 'W-4 (Employee’s Withholding Certificate)',
    'i9': 'I-9 (Employment Eligibility Verification)',
    'f8850': 'Form 8850 (for Work Opportunity Credit)',
}

export const DirectDepositDocuments = {
    'dd': 'Direct Deposit Authorization',
    'mdd': 'Multiple Direct Deposit Authorization',
}

export const taxDocumentDictionary = {
    'a4': 'A4 (Alabama)',
    'wh4': 'WH-4 (Indiana)',
    'k4': 'K-4 (Kentucky)',
    'miw4': 'MI-W4 (Michigan)',
    'it2104': 'IT-2104 (New York)',
    'it4': 'IT-4 (Ohio)',
    'rev419': 'REV-419 (Pennsylvania)',
    'va4': 'VA-4 (Virginia)'
}

export const OnboardingDocumentsShort = {
    w4: 'W-4',
    i9: 'I-9',
    f8850: 'Form 8850',
}

export const TaxDocumentsShort = {
    a4: 'A4',
    wh4: 'WH-4',
    k4: 'K-4',
    miw4: 'MI-W4',
    it2104: 'IT-2104',
    it4: 'IT-4',
    rev419: 'REV-419',
    va4: 'VA-4',
}

export const OnboardingDocumentsReadOnly = {
    'archivedDocument': 'Archived Document',
    'offerLetter': 'Offer Letter', 
    ...OnboardingDocuments,
    ...taxDocumentDictionary,
    ...DirectDepositDocuments
}

export const RejectedOnboardingDocumentsReadOnly = {
    'offerLetter': 'Offer Letter',
    'ssn': 'Social Security Card',
    'dl': "Driver's License",
    ...OnboardingDocuments,
    ...taxDocumentDictionary,
    ...DirectDepositDocuments
}

export const stateTaxDocuments = {
    'AL': 'a4',
    'IN': 'wh4',
    'KY': 'k4',
    'MI': 'miw4',
    'NY': 'it2104',
    'OH': 'it4',
    'PA': 'rev419',
    'VA': 'va4'
}

export async function downloadBase64(base64, title) {
    const response = await fetch(base64);
    const blob = await response.blob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = title;        
    document.body.appendChild(elem);
    elem.click();        
    document.body.removeChild(elem);
}

export async function reactPdfToBase64(doc) {
    const blob = await pdf(doc).toBlob();
    const thing = await new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        }
        reader.readAsDataURL(blob); 
    })
    return thing;
}

export async function base64PdfToBase64Images(base64) {
    const pdf = await pdfjs.getDocument(base64).promise;

    const pages = [];
    for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({scale: 1.5});
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height; /* viewport.height is NaN */
        canvas.width = viewport.width;  /* viewport.width is also NaN */
        await page.render({canvasContext, viewport}).promise;
        pages.push(canvas.toDataURL());
    };
    return pages;
}

export async function downloadReactPdf(doc, title = '') {
    const blob = await pdf(doc).toBlob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.target = '_blank';
    elem.download = title;        
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}

export function formatTimeNew(str) {
    if (!str) {
        return '';
    }
    const hours = parseInt(str.substring(0, 2));
    const minutes = parseInt(str.substring(3));
    const pm = hours > 12;
    const addLeadingZero = minutes < 10;

    return `${pm ? hours - 12 : hours}:${addLeadingZero ? '0' : ''}${minutes} ${pm ? 'PM' : 'AM'}`;
}

export function formatEin(ein) {
    return ein.toString().slice(0, 2) + '-' + ein.toString().slice(2);
}

export function naturalSort (array, type){
    var a, b, a1, b1, rx=/(\d+)|(\D+)/g, rd=/\d+/;
    return array.sort(function(as, bs){
        a= String(as[type]).toLowerCase().match(rx);
        b= String(bs[type]).toLowerCase().match(rx);
        while(a.length && b.length){
            a1= a.shift();
            b1= b.shift();
            if(rd.test(a1) || rd.test(b1)){
                if(!rd.test(a1)) return 1;
                if(!rd.test(b1)) return -1;
                if(a1!= b1) return a1-b1;
            }
            else if(a1!= b1) return a1> b1? 1: -1;
        }
        return a.length- b.length;
    });
}

export function getNameMatches(user, search){
    const partialName = `${user.firstName}${user.lastName}`;
    const partialNameReversed = `${user.lastName}${user.firstName}`;
    const fullName = `${user.firstName}${user.middleName}${user.lastName}`;
    const fullNameAsDisplayed = `${user.lastName}${user.firstName}${user.middleName}`;
    return (
            user.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
            || user.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            || partialName.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase()))
            || partialNameReversed.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase())
            || fullName.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase()
            || fullNameAsDisplayed.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase())
    )
}