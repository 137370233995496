/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from "react"
import QuickTable from "../../components/QuickTable";
import { toFixedMax, validateBig } from "../Payroll/payrollTools";
import { usdFormatter, validateDecimal } from "../../tools";

export default function BudgetDerivatives({budget}) {
    const headers = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly'];

    ////////////////////////
    /// INCOME TABLE
    ////////////////////////
    const incomeRows = [
        {
            key: 'vehicleBranding', 
            label: 'Vehicle Branding', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVehicleBrandingPromotionIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyVehicleBrandingPromotionIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVehicleBrandingPromotionIncome().toNumber())
        },
        {
            key: 'uniforms', 
            label: 'Uniforms', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyUniformIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyUniformIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyUniformIncome().toNumber())
        },
        {
            key: 'packagePayment', 
            label: 'Package Payment', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPackagePaymentIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyPackagePaymentIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPackagePaymentIncome().toNumber())
        },
        {
            key: 'stopPayment', 
            label: 'Stop Payment', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyStopPaymentIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyStopPaymentIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyStopPaymentIncome().toNumber())
        },
        {
            key: 'serviceCharge', 
            label: 'Service Charge', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyServiceChargeIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyServiceChargeIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyServiceChargeIncome().toNumber())
        },
        {
            key: 'fuelStopCharge', 
            label: 'Fuel Stop Charge', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyFuelStopChargeIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyFuelStopChargeIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyFuelStopChargeIncome().toNumber())
        },
        {
            key: 'largePackageMix', 
            label: 'Large Package Mix', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLargePackageMixIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyLargePackageMixIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLargePackageMixIncome().toNumber())
        },
        {
            key: 'advancedContractServiceCharge', 
            label: 'Advanced Contract Service Charge', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdvancedContractServiceChargeIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdvancedContractServiceChargeIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdvancedContractServiceChargeIncome().toNumber())
        },
        {
            key: 'totalRevenue', 
            label: 'Total Revenue', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.totalDailyRevenue(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.totalWeeklyRevenue().toNumber()),
            yearly: usdFormatter.format(budget.totalYearlyRevenue().toNumber())
        },
        {
            key: 'revenuePerDispatch', 
            label: 'Revenue Per Dispatch', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyRevenuePerDispatch(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyRevenuePerDispatch().toNumber()),
            yearly: usdFormatter.format(budget.yearlyRevenuePerDispatch().toNumber())
        },
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                {[0, 1, 2, 3, 4, 5, 6].map((day, index)=>{
                    return(
                        <td key={day}>
                            {row.values && row.values[day]}
                        </td>)
                    })}
                <td>
                    {row.weekly}
                </td>
                <td>
                    {row.yearly}
                </td>
            </tr>
        )
    });

    ////////////////////////
    /// EXPENSES TABLE
    ////////////////////////
    const expensesRows = [
        {
            key: 'driverWages',
            label: 'Driver Wages',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDriverWage(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDriverWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDriverWages().toNumber())
        },
        {
            key: 'aoWages',
            label: 'AO Wages',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAoWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAoWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAoWages().toNumber())
        },
        {
            key: 'bcWages', 
            label: 'BC Wages', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyBcWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyBcWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyBcWages().toNumber())
        },
        {
            key: 'adminWages', 
            label: 'Admin Wages', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdminWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminWages().toNumber())
        },
        {
            key: 'driverPto', 
            label: 'Driver PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDriverPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyDriverPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDriverPto().toNumber())
        },
        {
            key: 'aoPto', 
            label: 'AO PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAoPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAoPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAoPto().toNumber())
        },
        {
            key: 'bcPto', 
            label: 'BC PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyBcPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyBcPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyBcPto().toNumber())
        },
        {
            key: 'adminPto', 
            label: 'Admin PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdminPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminPto().toNumber())
        },
        {
            key: 'uniforms', 
            label: 'Uniforms', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyUniformCost(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyUniformCost().toNumber()),
            yearly: usdFormatter.format(budget.yearlyUniformCost().toNumber())
        },
        {
            key: 'payrollTaxes', 
            label: 'Payroll Taxes', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPayrollTaxExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyPayrollTaxExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPayrollTaxExpense().toNumber())
        },
        {
            key: 'workmansComp', 
            label: 'Workers Comp', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyWorkersCompExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyWorkersCompExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyWorkersCompExpense().toNumber())
        },
        {
            key: 'healthInsurance', 
            label: 'Health Insurance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyHealthInsurance(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyHealthInsurance().toNumber()),
            yearly: usdFormatter.format(budget.yearlyHealthInsurance().toNumber())
        },
        {
            key: 'lifeInsurance', 
            label: 'Life Insurance & Short Term Disability', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLifeInsurance(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyLifeInsurance().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLifeInsurance().toNumber())
        },
        {
            key: 'dentalAndVision', 
            label: 'Dental & Vision', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDentalAndVision(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDentalAndVision().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDentalAndVision().toNumber())
        },
        {
            key: 'pensionAndProfitSharing', 
            label: 'Pension/Profit Sharing', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPensionAndProfitSharing(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyPensionAndProfitSharing().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPensionAndProfitSharing().toNumber())
        },
        {
            key: 'drugScreens', 
            label: 'Drug Screens', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDrugScreens(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDrugScreens().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDrugScreens().toNumber())
        },
        {
            key: '401KMatch', 
            label: '401K Match', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.daily401KMatch(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weekly401KMatch().toNumber()),
            yearly: usdFormatter.format(budget.yearly401KMatch().toNumber())
        },
        {
            key: '401KAdmin', 
            label: '401K Administration', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.daily401KAdmin(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weekly401KAdmin().toNumber()),
            yearly: usdFormatter.format(budget.yearly401KAdmin().toNumber())
        },
        {
            key: 'dotPhysicals', 
            label: 'DOT Physicals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDotPhysicalExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDotPhysicalExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDotPhysicalExpense().toNumber())
        },
        {
            key: 'adminFees', 
            label: 'Admin Fees', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyAdminExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminExpense().toNumber())},
        {
            key: 'recruiting', 
            label: 'Recruiting', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyRecruitingExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyRecruitingExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyRecruitingExpense().toNumber())},
        {
            key: 'fuel', 
            label: 'Fuel', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyFuelExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyFuelExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyFuelExpense().toNumber())},
        {
            key: 'maintenance', 
            label: 'Maintenance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyMaintenanceExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyMaintenanceExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyMaintenanceExpense().toNumber())},
        {
            key: 'truckPayments', 
            label: 'Truck Payments', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTruckPaymentExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTruckPaymentExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTruckPaymentExpense().toNumber())},
        {
            key: 'licensesAndRegistration', 
            label: 'Licenses/Registration', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLicenseRegistrationExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyLicenseRegistrationExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLicenseRegistrationExpense().toNumber())},
        {
            key: 'insurance', 
            label: 'Insurance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyInsuranceExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyInsuranceExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyInsuranceExpense().toNumber())},
        {
            key: 'vanWashing', 
            label: 'Truck Washing', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVanWashingExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyVanWashingExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVanWashingExpense().toNumber())
        },
        {
            key: 'vanDecals', 
            label: 'Truck Decals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVanDecals(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyVanDecals().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVanDecals().toNumber())
        },
        {
            key: 'truckRentals', 
            label: 'Truck Rentals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTruckRentalExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTruckRentalExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTruckRentalExpense().toNumber())},
        {
            key: 'techCharges', 
            label: 'Technology Charges (Scanners/Tracking)', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTechnologyExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTechnologyExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTechnologyExpense().toNumber())},
        {
            key: 'miscExpenses', 
            label: 'Misc. Costs/Other Expenses', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyOtherExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyOtherExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyOtherExpense().toNumber())},
        {
            key: 'totalExpenses', 
            label: 'Total Expenses', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.totalDailyExpenses(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.totalWeeklyExpenses().toNumber()),
            yearly: usdFormatter.format(budget.totalYearlyExpenses().toNumber())},
        {
            key: 'costPerDispatch', 
            label: 'Cost Per Dispatch', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyExpensesPerDispatch(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyExpensesPerDispatch().toNumber()),
            yearly: usdFormatter.format(budget.yearlyExpensesPerDispatch().toNumber())
        },
    ].map((row, index) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                {[0, 1, 2, 3, 4, 5, 6].map((day)=>{
                    return(
                        <td key={day}>
                            {row.values && row.values[day]}
                        </td>)
                    })}
                <td>
                    {row.weekly}
                </td>
                <td>
                    {row.yearly}
                </td>
            </tr>
        )
    });

    ////////////////////////
    /// PROFIT TABLE
    ////////////////////////
    const profitRows = [
        {
            key: 'netProfit', 
            label: 'Net Profit', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyNetProfit(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyNetProfit().toNumber()),
            yearly: usdFormatter.format(budget.yearlyNetProfit().toNumber())
        },
        {
            key: 'margin', 
            label: 'Margin', 
            values: [0, 1, 2, 3, 4, 5, 6].map(()=>{
                return;
            }),
            weekly: toFixedMax(budget.weeklyMargin().toNumber(), 2) + '%',
            yearly: toFixedMax(budget.yearlyMargin().toNumber(), 2) + '%'
        },
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                {[0, 1, 2, 3, 4, 5, 6].map((day, index)=>{
                    if((row.key === 'margin')){
                        return <td key={day}></td>;
                    }
                    return(
                        <td key={day}>
                            {row.values[day]}
                        </td>)
                    })}
                <td>
                    {row.weekly}
                </td>
                <td>
                    {row.yearly}
                </td>
            </tr>
        )
    });

    return (
        <div>
            <QuickTable fontSize={15} title='Income' responsive={false} headers={['', ...headers]} rows={incomeRows} size='sm'/>
            <QuickTable fontSize={15} title='Expenses' responsive={false} headers={['', ...headers]} rows={expensesRows} size='sm'/>
            <QuickTable fontSize={15} title='Profit' responsive={false} headers={['', ...headers]} rows={profitRows} size='sm'/>
        </div>
    )
}

