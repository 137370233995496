/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faBinoculars, faRightFromBracket, faIdBadge, faUserGroup, faMoneyCheckDollar, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import Shadow from "./Shadow";
import CustomButton from "../../components/CustomButton";
import { ApiRequest } from "../../ApiManager.tsx";
import { NavLink } from "react-router-dom";
import SetCompanyPaychexAccount from "./SetCompanyPaychexAccount.tsx";
import MatchCompanyPaychexPayComponents from "./MatchCompanyPaychexPayComponents.tsx";
import MatchPaychexWorkers from "./MatchPaychexWorkers.tsx";
import SubmitPaychexPayroll from "./SubmitPaychexPayroll.tsx";

export default function Admin({handleCheckAuthentication}) {
    const [modalSwitch, setModalSwitch] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogout = () => {
        new ApiRequest('authentication', 'logout', setIsLoading, () => {
            handleCheckAuthentication();
        }).send();
    }

    return (
        <div style={{padding: 12, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: 12}}>
            <Card>
                <Card.Body style={{textAlign: 'center'}}>
                    <h3 style={{color: 'var(--bs-primary)', fontWeight: 'bold'}}>Tactical Contractor</h3>
                    <h4 style={{margin: 0, opacity: 0.65}}>~ Admin Dashboard ~</h4>
                </Card.Body>
            </Card>
            <Container fluid style={{padding: 0}}>
                <Row>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faBinoculars} size='2x'/>
                                <h4 style={{margin: 0}}>Shadow User</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Login as a user to view the website from their perspective. Stop shadowing at any time by pressing the <b>Stop Shadowing</b> button on the navbar.</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                                <Button variant="outline-primary" onClick={() => {setModalSwitch('shadow')}}>Shadow User</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faIdBadge} size='2x'/>
                                <h4 style={{margin: 0}}>Connect Company Paychex Account</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Connect a Paychex account to a company to enable Paychex payroll submission.</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                            <Button variant="outline-primary" onClick={() => {setModalSwitch('set-paychex-account')}}>Connect Paychex Account</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faMoneyBillTransfer} size='2x'/>
                                <h4 style={{margin: 0}}>Match Company Paychex Pay Components</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Assign system wages to their corresponding Paychex Pay Component.</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                            <Button variant="outline-primary" onClick={() => {setModalSwitch('match-pay-components')}}>Match Pay Components</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faUserGroup} size='2x'/>
                                <h4 style={{margin: 0}}>Match Paychex Workers</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Match Employees to their corresponding Paychex workers</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                            <Button variant="outline-primary" onClick={() => {setModalSwitch('match-workers')}}>Match Paychex Workers</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faUserGroup} size='2x'/>
                                <h4 style={{margin: 0}}>Submit Payroll to Paychex</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Submit a TTA-approved payroll period to Paychex</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                            <Button variant="outline-primary" onClick={() => {setModalSwitch('submit-payroll')}}>Submit Payroll</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faRightFromBracket} size='2x'/>
                                <h4 style={{margin: 0}}>Logout</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Return to the login page.</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                                <CustomButton label='Logout' onClick={handleLogout} isLoading={isLoading}/>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal backdrop='static' show={modalSwitch == 'shadow'} onHide={() => {setModalSwitch('')}} centered size='xl'>
                <Shadow handleCheckAuthentication={handleCheckAuthentication}/>
            </Modal>
            <Modal show={modalSwitch == 'set-paychex-account'} onHide={() => {setModalSwitch('')}} centered size="xl">
                <SetCompanyPaychexAccount/>
            </Modal>
            <Modal show={modalSwitch == 'match-pay-components'} onHide={() => {setModalSwitch('')}} centered size="xl">
                <MatchCompanyPaychexPayComponents/>
            </Modal>
            <Modal show={modalSwitch == 'match-workers'} onHide={() => {setModalSwitch('')}} centered size="xl">
                <MatchPaychexWorkers/>
            </Modal>
            <Modal show={modalSwitch == 'submit-payroll'} onHide={() => {setModalSwitch('')}} centered size="xl">
                <SubmitPaychexPayroll/>
            </Modal>
        </div>
    )
}