/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ApiRequest } from '../../ApiManager.tsx';
import DropdownControl from '../../components/DropdownControl';
import CustomButton from '../../components/CustomButton.js';
import LoadingWrapper from '../../components/LoadingWrapper.js';
import moment from 'moment';
import { usdFormatter } from '../../tools.js';
import PayrollEntry from '../Payroll/Models/PayrollEntry.js';
import PayrollPeriodTable from '../Payroll/PayrollPeriod/PayrollPeriodTable.js';
import PaychexPayrollSubmissionPreview from './PaychexPayrollSubmissionPreview.tsx';
import { generatePaychexJson } from '../Payroll/payrollTools.js';

export default function SubmitPaychexPayroll() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanyIdentifier, setSelectedCompanyIdentifier] = useState<number>();

  const [users, setUsers] = useState<any[]>([]);
  const [payComponents, setPayComponents] = useState<any>({});
  const [payrollPeriods, setPayrollPeriods] = useState<any[]>([]);
  const [selectedPayrollIdentifier, setSelectedPayrollIdentifier] = useState<number>();

  const selectedPayrollPeriod = useMemo(() => {
    return payrollPeriods.find((p) => p.uid == selectedPayrollIdentifier);
  }, [payrollPeriods, selectedPayrollIdentifier]);

  const [isLoadingEntries, setIsLoadingEntries] = useState(false);
  const [entries, setEntries] = useState<PayrollEntry[] | undefined>();
  const [paychexPayPeriods, setPaychexPayPeriods] = useState<any[]>([]);
  const [selectedPayPeriodId, setSelectedPayPeriodId] = useState<string>();
  const selectedPaychexPayPeriod: string | undefined = useMemo(() => {
    return paychexPayPeriods.find((p) => p.payPeriodId == selectedPayPeriodId);
  }, [paychexPayPeriods, selectedPayPeriodId]);

  const [showPreview, setShowPreview] = useState(false);

  const checks = useMemo(() => {
    return generatePaychexJson(entries ?? [], payComponents, users);
  }, [entries, payComponents, users]);

  const loadCompanies = useCallback(() => {
    new ApiRequest('admin', 'getCompaniesWithPaychexAccounts', setIsLoading, (response) => {
      setCompanies(response.companies);
    })
      .withNoAlertOnSuccess()
      .send();
  }, [setIsLoading, setCompanies]);

  useEffect(loadCompanies, []);

  const loadData = useCallback(() => {
    new ApiRequest('admin', 'getPaychexPayrollSubmissionResources', setIsLoading, (response) => {
      setPayComponents(response.payComponents);
      setPayrollPeriods(response.payrollPeriods);
      setUsers(response.users);
    })
      .withData({ companyIdentifier: selectedCompanyIdentifier })
      .withNoAlertOnSuccess()
      .send();
  }, [selectedCompanyIdentifier, setIsLoading, setPayComponents, setPayrollPeriods]);

  useEffect(() => {
    if (!!selectedCompanyIdentifier) {
      loadData();
    }
  }, [selectedCompanyIdentifier]);

  const loadPayrollPeriod = useCallback(() => {
    new ApiRequest('admin', 'getPayrollPeriod', setIsLoadingEntries, (response) => {
      const entriesDecoded = PayrollEntry.decodeArray(
        response.payrollPeriod.approvedEntries,
        response.payrollPeriod.periodStart,
        response.payrollPeriod.periodEnd
      ).sort((a: any, b: any) => {
        if (a.csaName < b.csaName) {
          return -1;
        } else if (a.csaName > b.csaName) {
          return 1;
        } else {
          if (a.isNew !== b.isNew) {
            if (a.isNew) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.name() < b.name()) {
              return -1;
            } else if (a.name() > b.name()) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });
      setEntries(entriesDecoded);
      setPaychexPayPeriods(response.paychexPayPeriods);
      if (response.paychexPayPeriods.length == 1) {
        setSelectedPayPeriodId(response.paychexPayPeriods[0].payPeriodId);
      }
    })
      .withUid(selectedPayrollIdentifier ?? -1)
      .withNoAlertOnSuccess()
      .send();
  }, [selectedPayrollIdentifier, setPayComponents, setPayrollPeriods]);

  useEffect(() => {
    if (selectedPayrollIdentifier) {
      loadPayrollPeriod();
    } else {
      setEntries(undefined);
    }
  }, [selectedPayrollIdentifier]);

  const handleSubmit = useCallback(() => {
    new ApiRequest('admin', 'submitPayrollToPaychex', setIsSubmitting, (response) => {
      setShowPreview(false);
      setSelectedPayrollIdentifier(undefined);
      setEntries(undefined);
    })
      .withData({ payrollIdentifier: selectedPayrollIdentifier, checks: checks, payPeriodId: selectedPayPeriodId })
      .send();
  }, [selectedPayrollIdentifier, checks, selectedPayPeriodId, setIsSubmitting, loadData]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Submit Payroll to Paychex</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
        <LoadingWrapper isLoading={isLoading}>
          <DropdownControl
            title="TTA Company"
            itemNames={companies.map((c) => c.companyName)}
            itemValues={companies.map((c) => c.uid)}
            selection={selectedCompanyIdentifier}
            setSelection={setSelectedCompanyIdentifier}
          />

          {!!selectedCompanyIdentifier && (
            <>
              <hr />

              <div key={selectedPayrollIdentifier}>
                <DropdownControl
                  title="Payroll Period"
                  itemNames={payrollPeriods.map(
                    (p) =>
                      `${p.csaName ?? p.companyName} | ${moment(p.periodStart).format('MMM D, YYYY')} - ${moment(
                        p.periodEnd
                      ).format('MMM D, YYYY')} | ${usdFormatter.format(p.gross)}`
                  )}
                  itemValues={payrollPeriods.map((p) => p.uid)}
                  selection={selectedPayrollIdentifier}
                  setSelection={setSelectedPayrollIdentifier}
                />
              </div>
            </>
          )}
          <LoadingWrapper isLoading={isLoadingEntries}>
            {!!entries && (
              <PayrollPeriodTable
                entries={entries}
                originalEntries={undefined}
                periodStart={selectedPayrollPeriod.periodStart}
                periodEnd={selectedPayrollPeriod.periodEnd}
              />
            )}
          </LoadingWrapper>
          {!!entries && (
            <>
              <hr />
              <DropdownControl
                title="Paychex Pay Period"
                itemNames={paychexPayPeriods.map((p) => `${p.description} | ${p.intervalCode ?? 'NON-RECURRING'}`)}
                itemValues={paychexPayPeriods.map((p) => p.payPeriodId)}
                selection={selectedPayPeriodId}
                setSelection={setSelectedPayPeriodId}
              />
            </>
          )}
        </LoadingWrapper>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          label="Preview & Submit"
          isLoading={false}
          disabled={checks.length == 0 || !selectedPayPeriodId}
          onClick={() => {
            setShowPreview(true);
          }}
        />
      </Modal.Footer>
      <Modal
        show={showPreview}
        size="lg"
        onHide={() => {
          setShowPreview(false);
        }}
      >
        <PaychexPayrollSubmissionPreview
          checks={checks}
          payComponents={payComponents}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </Modal>
    </>
  );
}
