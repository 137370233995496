/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';

export default function CustomButton({style = undefined, isLoading, label, onClick, disabled = false, type = undefined, variant = 'outline-primary', title = undefined}) {
    return (
        <Button title={title} type={type} disabled={disabled || isLoading} variant={variant ?? 'outline-primary'} style={style} onClick={onClick}>
            {isLoading ? <Spinner size='sm'/> : label}
        </Button>
    );
}
