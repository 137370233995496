/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ApiRequest } from '../../ApiManager.tsx';
import DropdownControl from '../../components/DropdownControl.js';
import CustomButton from '../../components/CustomButton.js';
import LoadingWrapper from '../../components/LoadingWrapper.js';
import CustomControl from '../../components/CustomControl.js';

export default function SetCompanyPaychexAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companies, setCompanies] = useState<any[]>([]);

  const [paychexId, setPaychexId] = useState<string>('');
  const [paychexSecret, setPaychexSecret] = useState<string>('');

  const [paychexCompanies, setPaychexCompanies] = useState<any[]>([]);
  const [selectedCompanyIdentifier, setSelectedCompanyIdentifier] = useState<number>();
  const [selectedPaychexCompanyId, setSelectedPaychexCompanyId] = useState<number>();

  const loadCompanies = useCallback(() => {
    new ApiRequest('admin', 'getCompanies', setIsLoading, (response) => {
      setCompanies(response.companies);
    })
      .withNoAlertOnSuccess()
      .send();
  }, [setIsLoading, setCompanies]);

  useEffect(loadCompanies, []);

  const loadPaychexCompanies = useCallback(() => {
    new ApiRequest('admin', 'getCompaniesForPaychexAccount', setIsAuthenticating, (response) => {
      setPaychexCompanies(response.paychexCompanies);
    })
      .withData({ companyIdentifier: selectedCompanyIdentifier, paychexId: paychexId, paychexSecret: paychexSecret })
      .withNoAlertOnSuccess()
      .send();
  }, [selectedCompanyIdentifier, paychexId, paychexSecret, setIsAuthenticating, setCompanies, setPaychexCompanies]);

  const handleSubmit = useCallback(() => {
    new ApiRequest('admin', 'setCompanyPaychexAccount', setIsSubmitting, (response) => {
      setSelectedCompanyIdentifier(undefined);
      setPaychexId('');
      setPaychexSecret('');
      setPaychexCompanies([]);
      setSelectedPaychexCompanyId(undefined);
      loadCompanies();
    })
      .withData({ companyIdentifier: selectedCompanyIdentifier, paychexId: paychexId, paychexSecret: paychexSecret, paychexCompanyId: selectedPaychexCompanyId })
      .send();
  }, [selectedCompanyIdentifier, paychexId, paychexSecret, selectedPaychexCompanyId, setIsSubmitting]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Set Company Paychex ID</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
        <LoadingWrapper isLoading={isLoading}>
          <DropdownControl
            title="TTA Company"
            itemNames={companies.map((c) => `${c.companyName}${c.paychexCompanyId ? ` (Assigned to ${c.paychexCompanyId})` : ''}`)}
            itemValues={companies.map((c) => c.uid)}
            disabled={paychexCompanies.length > 0}
            selection={selectedCompanyIdentifier}
            setSelection={setSelectedCompanyIdentifier}
          />
          <div style={{display: 'flex', gap: 6, flexWrap: 'wrap'}}>
            <div style={{flexGrow: 1}}>
              <CustomControl
                value={paychexId}
                disabled={paychexCompanies.length > 0}
                onChange={(e: any) => {
                  setPaychexId(e.target.value);
                }}
                title="Paychex ID"
              />
            </div>
            <div style={{flexGrow: 1}}>
              <CustomControl
                value={paychexSecret}
                disabled={paychexCompanies.length > 0}
                onChange={(e: any) => {
                  setPaychexSecret(e.target.value);
                }}
                title="Paychex Secret"
              />
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', gap: 6}}>
            { paychexCompanies.length > 0 && 
              <CustomButton
                label="Cancel"
                isLoading={isAuthenticating}
                variant='outline-danger'
                onClick={() => {
                  setPaychexCompanies([]);
                  setSelectedPaychexCompanyId(undefined);
                }}
              />   
            }
            <CustomButton
              label="Authenticate With Paychex"
              isLoading={isAuthenticating}
              disabled={!paychexId || !paychexSecret || !selectedCompanyIdentifier || paychexCompanies.length > 0}
              onClick={loadPaychexCompanies}
            />
          </div>

          {paychexCompanies.length > 0 && (
            <DropdownControl
              title="Paychex Company"
              itemNames={paychexCompanies.map((c) => `${c.legalName} (${c.companyId}) (${c.displayId})`)}
              itemValues={paychexCompanies.map((c) => c.companyId)}
              selection={selectedPaychexCompanyId}
              setSelection={setSelectedPaychexCompanyId}
            />
          )}
        </LoadingWrapper>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          label="Assign to Paychex Company"
          disabled={!selectedCompanyIdentifier || !selectedPaychexCompanyId}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        />
      </Modal.Footer>
    </>
  );
}
