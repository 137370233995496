/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from "react"
import { View, Page, Document, Text, Image } from "@react-pdf/renderer";
import Cookies from "universal-cookie";
import logo from '../../assets/TTA-Logo-150.png';
import PDFTable, { PDFTableRow } from "../../components/PDFTable";
import { usdFormatter, validateDecimal, validateInteger } from "../../tools";
import { toFixedMax } from "../Payroll/payrollTools";

export default function BudgetPDF({budget}) {

    ///////////////////////
    /// ASSUMPTIONS ROWS
    ///////////////////////
    const assumptionRows = [
    {
        key: 'vehicleBranding', 
        label: 'Vehicle Branding',
        frequency: 'Yearly',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.vehicleBranding))
    },
    {
        key: 'uniforms', 
        label: 'Uniforms',
        frequency: 'Yearly',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.uniforms))
    },
    {
        key: 'packageCharge', 
        label: 'Package Charge',
        frequency: 'Activity Based',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.packageCharge))
    },
    {
        key: 'stopCharge', 
        label: 'Ground Stop Charge',
        frequency: 'Activity Based',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.stopCharge))
    },
    {
        key: 'eCommStopCharge', 
        label: 'E-Comm Stop Charge',
        frequency: 'Activity Based',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.eCommStopCharge))
    },
    {
        key: 'serviceCharge', 
        label: 'Service Charge',
        frequency: 'Yearly',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.serviceCharge))
    },
    {
        key: 'perStopFuelSurcharge', 
        label: 'Per Stop Fuel Charge',
        frequency: 'Activity Based',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.perStopFuelSurcharge))
    },
    {
        key: 'largePackageMix', 
        label: 'Large Package Mix %',
        frequency: '%',
        value: validateDecimal(budget.budgetAssumptions.largePackageMixDeliveryPercentage) + '%'
    },
    {
        key: 'largePackageCharge', 
        label: 'Large Package Charge',
        frequency: 'Activity Based',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.largePackageMixCharge))
    },
    {
        key: 'advancedContractServiceCharge', 
        label: 'Advanced Contract Service Charge',
        frequency: 'Yearly',
        value: usdFormatter.format(validateDecimal(budget.budgetAssumptions.advanceServiceCharge))
    },
    {
        key: 'totalDeliveryPackages', 
        label: 'Total Delivery Packages',
        frequency: 'Yearly',
        value: validateDecimal(budget.budgetAssumptions.totalDeliveryPackages).toLocaleString()
    },
    ].map((row) => {
        return (
            <PDFTableRow key={row.key} columns={[row.label, row.frequency, row.value]} fontSize={10}/>
        )
    })

    ///////////////////////
    /// EXPENSES ROWS
    ///////////////////////
    const expensesRows = [
        {
            key: 'lightDailyWage',
            label: 'Driver Daily Wage (Light)',
            frequency: 'Daily',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.driverWageLight))
        },
        {
            key: 'standardDailyWage',
            label: 'Driver Daily Wage (Standard)',
            frequency: 'Daily',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.driverWageStandard))
        },
        {
            key: 'straightDailyWage',
            label: 'Driver Daily Wage (Straight)',
            frequency: 'Daily',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.driverWageStraight))
        },
        {
            key: 'aoWages',
            label: 'AO Wages',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.aoWages))
        },
        {
            key: 'bcWages',
            label: 'BC Wages',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.bcWages))
        },
        {
            key: 'adminWages',
            label: 'Admin Wages',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.adminWages))
        },
        {
            key: 'driverPto',
            label: 'Driver PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.driverPto))
        },
        {
            key: 'aoPto',
            label: 'AO PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.aoPto))
        },
        {
            key: 'bcPto',
            label: 'BC PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.bcPto))
        },
        {
            key: 'adminPto',
            label: 'Admin PTO',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.adminPto))
        },
        {
            key: 'uniforms',
            label: 'Uniforms',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.uniforms))
        },
        {
            key: 'payrollTaxes',
            label: 'Payroll Taxes',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.payrollTaxes) + '%'
        },
        {
            key: 'driverWorkersComp',
            label: 'Driver\'s Workers Comp',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.driverWorkersComp) + '%'
        },
        {
            key: 'bcWorkersComp',
            label: 'BC Workers Comp',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.bcWorkersComp) + '%'
        },
        {
            key: 'healthInsurance',
            label: 'Health Insurance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.healthInsurance))
        },
        {
            key: 'lifeInsurance',
            label: 'Life Insurance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.lifeInsurance))
        },
        {
            key: 'dentalAndVision',
            label: 'Dental & Vision',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.dentalAndVision))
        },
        {
            key: 'pensionAndProfitSharing',
            label: 'Pension/Profit Sharing',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.pensionAndProfitSharing))
        },
        {
            key: 'drugScreens',
            label: 'Drug Screens',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.drugScreens))
        },
        {
            key: '401KMatch',
            label: '401K Match',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.four01KMatch))
        },
        {
            key: '401KAdmin',
            label: '401K Administration',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.four01KAdmin))
        },
        {
            key: 'dotPhysicals',
            label: 'DOT Physicals',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.dotPhysicals))
        },
        {
            key: 'adminFee',
            label: 'Admin Fee',
            frequency: '%',
            value: validateDecimal(budget.budgetExpenses.adminFee) + '%'
        },
        {
            key: 'recruiting',
            label: 'Recruiting',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.recruiting))
        },
        {
            key: 'fuel',
            label: 'Fuel',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.fuel))
        },
        {
            key: 'maintenance',
            label: 'Maintenance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.maintenance))
        },
        {
            key: 'truckPayments',
            label: 'Truck Payments',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.truckPayments))
        },
        {
            key: 'licenseAndRegistration',
            label: 'License/Registrations',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.licenseAndRegistration))
        },
        {
            key: 'insurance',
            label: 'Insurance',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.insurance))
        },
        {
            key: 'vanWashing',
            label: 'Truck Washing',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.vanWashing))
        },
        {
            key: 'vanDecals',
            label: 'Truck Decals',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.vanDecals))
        },
        {
            key: 'truckRentals',
            label: 'Truck Rentals',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.truckRentals))
        },
        {
            key: 'technology',
            label: 'Technology (Scanners/Tracking)',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.technology))
        },
        {
            key: 'other',
            label: 'Misc. Costs/Other Expenses',
            frequency: 'Monthly',
            value: usdFormatter.format(validateDecimal(budget.budgetExpenses.combineOtherExpenses()))
        },
    ].map((row)=> <PDFTableRow key={row.key} columns={[row.label, row.frequency, row.value]} fontSize={10}/> )
    
    ///////////////////////
    /// PRODUCTIVTY ROWS
    ///////////////////////

    const weeklyDispatches = [0, 1, 2, 3, 4, 5, 6].reduce((prev, dayIndex)=> {
        return prev + validateInteger(budget.budgetProductivity[dayIndex].dispatchesLight)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStandard)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStraight);
    }, 0)

    const yearlyDispatches = weeklyDispatches * 52;

    const weeklyStops = [0, 1, 2, 3, 4, 5, 6].reduce((prev, dayIndex)=> {
        return prev + validateDecimal(budget.budgetProductivity[dayIndex].stops)
    }, 0)

    const weeklyAvgStops =  weeklyStops/weeklyDispatches == 'Infinity' || isNaN(weeklyStops/weeklyDispatches) ? '0' : toFixedMax(weeklyStops/weeklyDispatches, 2);

    const weeklyPackages = [0, 1, 2, 3, 4, 5, 6].reduce((prev, dayIndex)=> {
        return prev + validateDecimal(budget.budgetProductivity[dayIndex].packages);
    }, 0)

    const dispatchRow = <PDFTableRow key='dispatchRow' columns={['Dispatches']} fontSize={10} center={true} padding={'5px 5px 5px 5px'}/>
    const lightDispatchesRow = <PDFTableRow key='lightDispatchesRow' columns={['Light', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesLight);} ), '', '']} fontSize={10}/>  
    const standardDispatchesRow = <PDFTableRow key='standardDispatchesRow' columns={['Standard', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesStandard);} ), '', '']} fontSize={10}/>  
    const straightDispatchesRow = <PDFTableRow key='straightDispatchesRow' columns={['Straight', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesStraight);} ), '', '']} fontSize={10}/>
    const totalDispatchesRow = <PDFTableRow key='totalDispatchesRow' columns={['Total', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateInteger(budget.budgetProductivity[dayIndex].dispatchesLight)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStandard)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStraight);} ), weeklyDispatches.toLocaleString(), yearlyDispatches.toLocaleString()]} fontSize={10}/>
    const stopMixRow = <PDFTableRow key='stopMixRow' columns={['Stop Mix Percentage']} fontSize={10} center={true}  padding={'5px 5px 5px 5px'}/>
    const eCommRow = <PDFTableRow key='eCommRow' columns={['E-Commerce', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return validateDecimal(budget.budgetProductivity[dayIndex].eCommStopPercentage) ? validateDecimal(budget.budgetProductivity[dayIndex].eCommStopPercentage)  + '%' : '0%';} ), '', '']} fontSize={10}/> 
    const groundRow = <PDFTableRow key='groundRow' columns={['Ground', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return 100 - validateDecimal(budget.budgetProductivity[dayIndex].eCommStopPercentage) ? toFixedMax(100 - validateDecimal(budget.budgetProductivity[dayIndex].eCommStopPercentage), 2) + '%' : '0%'} ), '', '']} fontSize={10}/> 
    const stopsAndPackagesRow = <PDFTableRow key='stopsAndPackagesRow' columns={['Stop & Packages']} fontSize={10} center={true}  padding={'5px 5px 5px 5px'}/>
    const avgStopsRow = <PDFTableRow key='avgStopsRow' columns={['Avg Stops/Truck', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
    const avgStops = validateInteger(budget.budgetProductivity[dayIndex].stops)/(validateInteger(budget.budgetProductivity[dayIndex].dispatchesLight)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStandard)+validateInteger(budget.budgetProductivity[dayIndex].dispatchesStraight));
        return avgStops == "Infinity" || isNaN(avgStops) ? "0" :  toFixedMax(avgStops, 2);} ), weeklyAvgStops, '']} fontSize={10}/> 
    const packagesRow = <PDFTableRow key='packagesRow' columns={['Packages', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return Math.round(validateDecimal(budget.budgetProductivity[dayIndex].packages)).toLocaleString()} ), Math.round(weeklyPackages).toLocaleString(), '']} fontSize={10}/> 
    const stopsRow = <PDFTableRow key='stopsRow' columns={['Stops', ...[0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{return Math.round(validateDecimal(budget.budgetProductivity[dayIndex].stops)).toLocaleString()} ), Math.round(weeklyStops).toLocaleString(), '']} fontSize={10}/> 
    
    const productivityRows = [dispatchRow, lightDispatchesRow, standardDispatchesRow, straightDispatchesRow, totalDispatchesRow, stopMixRow, groundRow, eCommRow, stopsAndPackagesRow, stopsRow,
        avgStopsRow, packagesRow
    ];

    ///////////////////////
    /// INCOME ROWS DERIVED VALUES
    ///////////////////////
    const incomeRowsDerived = [
        {
            key: 'vehicleBranding', 
            label: 'Vehicle Branding', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVehicleBrandingPromotionIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyVehicleBrandingPromotionIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVehicleBrandingPromotionIncome().toNumber())
        },
        {
            key: 'uniforms', 
            label: 'Uniforms', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyUniformIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyUniformIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyUniformIncome().toNumber())
        },
        {
            key: 'packagePayment', 
            label: 'Package Payment', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPackagePaymentIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyPackagePaymentIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPackagePaymentIncome().toNumber())
        },
        {
            key: 'stopPayment', 
            label: 'Stop Payment', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyStopPaymentIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyStopPaymentIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyStopPaymentIncome().toNumber())
        },
        {
            key: 'serviceCharge', 
            label: 'Service Charge', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyServiceChargeIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyServiceChargeIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyServiceChargeIncome().toNumber())
        },
        {
            key: 'fuelStopCharge', 
            label: 'Fuel Stop Charge', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyFuelStopChargeIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyFuelStopChargeIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyFuelStopChargeIncome().toNumber())
        },
        {
            key: 'largePackageMix', 
            label: 'Large Package Mix', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLargePackageMixIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyLargePackageMixIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLargePackageMixIncome().toNumber())
        },
        {
            key: 'advancedContractServiceCharge', 
            label: 'Advanced Contract Service Charge', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdvancedContractServiceChargeIncome(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdvancedContractServiceChargeIncome().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdvancedContractServiceChargeIncome().toNumber())
        },
        {
            key: 'totalRevenue', 
            label: 'Total Revenue', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.totalDailyRevenue(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.totalWeeklyRevenue().toNumber()),
            yearly: usdFormatter.format(budget.totalYearlyRevenue().toNumber())
        },
        {
            key: 'revenuePerDispatch', 
            label: 'Revenue Per Dispatch', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyRevenuePerDispatch(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyRevenuePerDispatch().toNumber()),
            yearly: usdFormatter.format(budget.yearlyRevenuePerDispatch().toNumber())
        },
    ].map((row) => {
        return (
            <PDFTableRow key={row.key} columns={[row.label, ...row.values, row.weekly, row.yearly]} fontSize={10}/> 
        )
    });

    ////////////////////////
    /// EXPENSES ROWS 2 DERIVED VALUES
    ////////////////////////
    const expensesRowsDerived = [
        {
            key: 'driverWages',
            label: 'Driver Wages',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDriverWage(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDriverWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDriverWages().toNumber())
        },
        {
            key: 'aoWages',
            label: 'AO Wages',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAoWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAoWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAoWages().toNumber())
        },
        {
            key: 'bcWages', 
            label: 'BC Wages', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyBcWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyBcWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyBcWages().toNumber())
        },
        {
            key: 'adminWages', 
            label: 'Admin Wages', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminWages(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdminWages().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminWages().toNumber())
        },
        {
            key: 'driverPto', 
            label: 'Driver PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDriverPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyDriverPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDriverPto().toNumber())
        },
        {
            key: 'aoPto',
            label: 'AO PTO',
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAoPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAoPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAoPto().toNumber())
        },
        {
            key: 'bcPto', 
            label: 'BC PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyBcPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyBcPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyBcPto().toNumber())
        },
        {
            key: 'adminPto', 
            label: 'Admin PTO', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminPto(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyAdminPto().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminPto().toNumber())
        },
        {
            key: 'uniforms', 
            label: 'Uniforms', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyUniformCost(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyUniformCost().toNumber()),
            yearly: usdFormatter.format(budget.yearlyUniformCost().toNumber())
        },
        {
            key: 'payrollTaxes', 
            label: 'Payroll Taxes', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPayrollTaxExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyPayrollTaxExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPayrollTaxExpense().toNumber())
        },
        {
            key: 'workmansComp', 
            label: 'Workers Comp', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyWorkersCompExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyWorkersCompExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyWorkersCompExpense().toNumber())
        },
        {
            key: 'healthInsurance', 
            label: 'Health Insurance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyHealthInsurance(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyHealthInsurance().toNumber()),
            yearly: usdFormatter.format(budget.yearlyHealthInsurance().toNumber())
        },
        {
            key: 'lifeInsurance', 
            label: 'Life Insurance & Short Term Disability', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLifeInsurance(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyLifeInsurance().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLifeInsurance().toNumber())
        },
        {
            key: 'dentalAndVision', 
            label: 'Dental & Vision', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDentalAndVision(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyDentalAndVision().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDentalAndVision().toNumber())
        },
        {
            key: 'pensionAndProfitSharing', 
            label: 'Pension/Profit Sharing', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyPensionAndProfitSharing(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyPensionAndProfitSharing().toNumber()),
            yearly: usdFormatter.format(budget.yearlyPensionAndProfitSharing().toNumber())
        },
        {
            key: 'drugScreens', 
            label: 'Drug Screens', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDrugScreens(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weeklyDrugScreens().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDrugScreens().toNumber())
        },
        {
            key: '401KMatch', 
            label: '401K Match', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.daily401KMatch(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weekly401KMatch().toNumber()),
            yearly: usdFormatter.format(budget.yearly401KMatch().toNumber())
        },
        {
            key: '401KAdmin', 
            label: '401K Administration', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.daily401KAdmin(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.weekly401KAdmin().toNumber()),
            yearly: usdFormatter.format(budget.yearly401KAdmin().toNumber())
        },
        {
            key: 'dotPhysicals', 
            label: 'Dot Physicals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyDotPhysicalExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyDotPhysicalExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyDotPhysicalExpense().toNumber())
        },
        {
            key: 'adminFees', 
            label: 'Admin Fees', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyAdminExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyAdminExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyAdminExpense().toNumber())},
        {
            key: 'recruiting', 
            label: 'Recruiting', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyRecruitingExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyRecruitingExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyRecruitingExpense().toNumber())},
        {
            key: 'fuel', 
            label: 'Fuel', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyFuelExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyFuelExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyFuelExpense().toNumber())},
        {
            key: 'maintenance', 
            label: 'Maintenance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyMaintenanceExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyMaintenanceExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyMaintenanceExpense().toNumber())},
        {
            key: 'truckPayments', 
            label: 'Truck Payments', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTruckPaymentExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTruckPaymentExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTruckPaymentExpense().toNumber())},
        {
            key: 'licensesAndRegistration', 
            label: 'Licenses/Registration', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyLicenseRegistrationExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyLicenseRegistrationExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyLicenseRegistrationExpense().toNumber())},
        {
            key: 'insurance', 
            label: 'Insurance', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyInsuranceExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyInsuranceExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyInsuranceExpense().toNumber())},
        {
            key: 'vanWashing', 
            label: 'Truck Washing', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVanWashingExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyVanWashingExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVanWashingExpense().toNumber())
        },
        {
            key: 'vanDecals', 
            label: 'Truck Decals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyVanDecals(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyVanDecals().toNumber()),
            yearly: usdFormatter.format(budget.yearlyVanDecals().toNumber())
        },
        {
            key: 'truckRentals', 
            label: 'Truck Rentals', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTruckRentalExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTruckRentalExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTruckRentalExpense().toNumber())},
        {
            key: 'techCharges', 
            label: 'Technology Charges (Scanners/Tracking)', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyTechnologyExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyTechnologyExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyTechnologyExpense().toNumber())},
        {
            key: 'miscExpenses', 
            label: 'Misc. Costs/Other Expenses', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyOtherExpense(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyOtherExpense().toNumber()),
            yearly: usdFormatter.format(budget.yearlyOtherExpense().toNumber())},
        {
            key: 'totalExpenses', 
            label: 'Total Expenses', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.totalDailyExpenses(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.totalWeeklyExpenses().toNumber()),
            yearly: usdFormatter.format(budget.totalYearlyExpenses().toNumber())},
        {
            key: 'costPerDispatch', 
            label: 'Cost Per Dispatch', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyExpensesPerDispatch(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyExpensesPerDispatch().toNumber()),
            yearly: usdFormatter.format(budget.yearlyExpensesPerDispatch().toNumber())
        },
    ].map((row) => {
        return (
            <PDFTableRow key={row.key} columns={[row.label, ...row.values, row.weekly, row.yearly]} fontSize={10}/> 
        )
    });

    ////////////////////////
    /// PROFIT TABLE
    ////////////////////////
    const profitRowsDerived = [
        {
            key: 'totalRevenue', 
            label: 'Total Revenue', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.totalDailyRevenue(dayIndex).toNumber())
            }),
            weekly: usdFormatter.format(budget.totalWeeklyRevenue().toNumber()),
            yearly: usdFormatter.format(budget.totalYearlyRevenue().toNumber())
        },
        {
            key: 'totalExpenses', 
            label: 'Total Expenses', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.totalDailyExpenses(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.totalWeeklyExpenses().toNumber()),
            yearly: usdFormatter.format(budget.totalYearlyExpenses().toNumber())
        },
        {
            key: 'netProfit', 
            label: 'Net Profit', 
            values: [0, 1, 2, 3, 4, 5, 6].map((dayIndex)=>{
                return usdFormatter.format(budget.dailyNetProfit(dayIndex).toNumber());
            }),
            weekly: usdFormatter.format(budget.weeklyNetProfit().toNumber()),
            yearly: usdFormatter.format(budget.yearlyNetProfit().toNumber())
        },
        {
            key: 'margin', 
            label: 'Margin', 
            values: [0, 1, 2, 3, 4, 5, 6].map(()=>{
                return;
            }),
            weekly: toFixedMax(budget.weeklyMargin().toNumber(), 2) + '%',
            yearly: toFixedMax(budget.yearlyMargin().toNumber(), 2) + '%'
        },
    ].map((row) => {
        return (
            <PDFTableRow key={row.key} columns={[row.label, ...row.values, row.weekly, row.yearly]} fontSize={10}/> 
        )
    });

    return (
    <Document> 
        <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}} orientation='landscape'>
            <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:15}}>
                <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: 12}}>
                <Text>{budget.title}</Text>
            </View>
            {/* Assumptions table */}
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Assumptions' headers={['Assumption', 'Frequency', 'Amount']} rows={assumptionRows} fontSize={10}/>
            </View>

            {/* Expenses Table */}
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Expenses' headers={['Expense', 'Frequency', 'Amount']} rows={expensesRows} fontSize={10}/>
            </View>

            {/* Productivity Table */}
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Productivity' headers={['', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly']} rows={productivityRows} fontSize={10}/>
            </View>

            {/* Income Derived Table */}
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Income' headers={['', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly']} rows={incomeRowsDerived} fontSize={10}/>
            </View>

            {/* Expenses Derived Table */}
            <View wrap={true} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Expenses' headers={['', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly']} rows={expensesRowsDerived} fontSize={10}/>
            </View>

            {/* Profit Derived Table */}
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='Profit' headers={['', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly']} rows={profitRowsDerived} fontSize={10}/>
            </View>
 
        </Page>
    </Document>
    )
}