/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../components/CustomButton';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AlertModal from '../../../components/AlertModals/AlertModal';
import { toFixedMax } from '../../Payroll/payrollTools';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRUserSchedulePTODaySelector({selectedItem, handleAddPTO}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDays, setSelectedDays] = useState({});
    const [ptoBalance, setPtoBalance] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [allowNegativePto, setAllowNegativePto] = useState(1);

    useEffect(() => { 
        loadData();
    }, []);

    function loadData(){
        new ApiRequest('hr', 'getPtoBalance', setIsLoading, (response)=>{
            setPtoBalance(response.balance);
            setAllowNegativePto(response.allowNegativePto);
        }).withData({companyUserIdentifier: selectedItem.companyUserIdentifier}).withNoAlertOnSuccess().send()
    }

    function handleCreatePTO(){
        const numberOfDays = moment(selectedItem.endDate).diff(moment(selectedItem.date), 'days') + 1;
        const scheduleItems  = [];
        
        for (let i = 0; i < numberOfDays; i++) {
            const date = moment(selectedItem.date).add(i, 'days').format('YYYY-MM-DD');
            const isSelected = selectedDays[date] !== undefined && selectedDays[date] !== 0;
            scheduleItems.push({
                companyUserIdentifier: selectedItem.companyUserIdentifier,
                date: date,
                notes: selectedItem.notes,
                scheduleType: isSelected ? 'pto' : 'dayOff',
                hours: selectedDays[date]
            });
        }
        new ApiRequest('hr', 'acceptRequestOff', setIsSubmitting, () => handleAddPTO()).withData({data: scheduleItems, uid: selectedItem.uid}).send();
    }

    const handleSelectDay = (momentDate) => {
        const date = momentDate.format('YYYY-MM-DD');
        let newObj = structuredClone(selectedDays);

        if (!newObj[date]) {
            newObj[date] = 8;
        } else if (newObj[date] === 8) {
            newObj[date] = 6
        } else if (newObj[date] === 6) {
            newObj[date] = 4
        } else if (newObj[date] === 4) {
            newObj[date] = 2
        } else if (newObj[date] === 2) {
            newObj[date] = 0
        }
        setSelectedDays(newObj);
    }

    const days = [];
    const firstDay = moment(selectedItem.date).startOf('week');
    const lastDay = moment(selectedItem.endDate).endOf('week');

    for (let i = 0; i < lastDay.diff(firstDay, 'days') + 1; i++) {
        days.push(moment(firstDay).add(i, 'days'));
    }

    const dayElements = days.map((day) => {
        const isInRange = moment(day).isBetween(moment(selectedItem.date), moment(selectedItem.endDate), 'days', '[]');
        const hoursOnDay = selectedDays[day.format('YYYY-MM-DD')] ? selectedDays[day.format('YYYY-MM-DD')] : 0;

        const selectionOverlay = (
            <CircularProgressbar value={hoursOnDay} maxValue={8} strokeWidth={50} styles={buildStyles({strokeLinecap: 'butt', pathColor: "yellow",
            trailColor: "white"})}/>
        )

        return (
            <div 
                key={day.format('YYYY-MM-DD')} 
                onClick={isInRange ? () => {handleSelectDay(day)} : () => {}} 
                className='cursor-pointer' 
                style={{position: 'relative', display: 'flex', backgroundColor: 'white', flexDirection: 'column', opacity: isInRange ? 1 : 0.2, justifyContent: 'center', alignItems: 'center', border: '2px var(--bs-primary) solid', width: 58, height: 58, borderRadius: 29, padding: 8}}
                >
                <div style={{position: 'absolute', zIndex: 0}}>
                    {selectionOverlay}
                </div>
                <p className='unselectable-text' style={{margin: 0, zIndex: 1, fontSize: 15}}>{day.format('D')}</p>
                <p className='unselectable-text' style={{margin: 0, zIndex: 1, fontSize: 15}}>{day.format('ddd')}</p>
                
            </div>
        )
    });

    const ptoHoursSelected = Object.values(selectedDays).reduce((prev, curr) => {return prev + curr}, 0.0);
    const remainingBalance = toFixedMax(ptoBalance - ptoHoursSelected, 3);
    const disableButtonNegativeBalance = !allowNegativePto && remainingBalance < 0 && ptoHoursSelected > 0;

    return (
        <>
            <Modal.Header>
                <Modal.Title>Select Days for PTO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Select the days for which the employee will receive paid time off from the requested date range:
                <p style={{textAlign: 'center', fontWeight: 'bold'}}>{moment(selectedItem.date).format('MMM D, YYYY') + ' - ' + moment(selectedItem.endDate).format('MMM D, YYYY')}</p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 8}}>
                    {dayElements}
                </div>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontSize: 15}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>PTO Hours Selected</span>
                    <span style={{fontWeight: 'bold'}}>{ptoHoursSelected}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>PTO Hours Available</span>
                    <span style={{fontWeight: 'bold'}}>{toFixedMax(ptoBalance, 3)}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>Remaining Balance</span>
                    <span style={{fontWeight: 'bold'}}>{remainingBalance}</span>
                </div>
            </Modal.Footer>
            <Modal.Footer style={{display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 8}}>
                {disableButtonNegativeBalance ? <p style={{margin: 0, color: 'red', fontSize: 12}}>Your company does not allow PTO approval with a negative balance</p> 
                : <CustomButton label='Submit' disabled={selectedDays.length === 0 || showAlert || disableButtonNegativeBalance} isLoading={isLoading} onClick={()=>{setShowAlert(true)}}/>}
            </Modal.Footer>
            <Modal show={showAlert} onHide={()=>setShowAlert(false)}>
                <AlertModal isLoading={isSubmitting} title='Are you sure you want to submit?' callBack={handleCreatePTO} buttonLabel='Submit' message='If your employee would like to use PTO please make sure you have selected PTO days and hours.'/>
            </Modal>
        </>
    )
}