/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";

import QuickTable from "../../../../../../components/QuickTable";
import { Button, Card, InputGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTriangleExclamation, faX } from "@fortawesome/free-solid-svg-icons";
import { bigToUsd, validateDecimal, validateInteger, validateUsd } from "../../../../payrollTools";
import { usdFormatter } from "../../../../../../tools";
import moment from "moment";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";


export default function OverthresholdStopWagesEditor({entry, handleSetWeek, index, handleRecalculateStopWages, handleRecalculateDaysWorked}) {
    const [showModal, setShowModal] = useState(false);
    const [showDaysWorkedModal, setShowDaysWorkedModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleSetOTStopWage = (dayIndex, value) => {
        const newOTStopWages = Array.from(entry.weeks[index].stops);
        newOTStopWages[dayIndex] = value;
        handleSetWeek(index, 'stops', newOTStopWages);
    }

    const handleSetOTStopWageDefinition = (dayIndex, key, value) => {
        const newOTStopWageDefinitions = Array.from(entry.weeks[index].otStopWageDefinitions);
        newOTStopWageDefinitions[dayIndex][key] = value;
        handleSetWeek(index, 'otStopWageDefinitions', newOTStopWageDefinitions);
    }

    const payrollPeriodStartIndex = parseInt(moment(entry.periodStart).format('d'));
    const payrollPeriodStartOffset = payrollPeriodStartIndex == 6 ? 0 : payrollPeriodStartIndex + 1; //data it stored as [sat, sun, mon, ...] while table columns are based on the payroll period date range

    const stopColumns = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        const offsetDayIndex = (dayIndex + payrollPeriodStartOffset) % 7;

        return (
            <td key={dayIndex}>
                <Form.Control 
                    style={{padding: '2px 6px 2px 6px'}} 
                    type='number' 
                    min={0} 
                    value={entry.weeks[index].stops[offsetDayIndex] ?? ''} 
                    placeholder='Stops' 
                    onChange={(event) => {handleSetOTStopWage(offsetDayIndex, event.target.value)}}
                    onBlur={() => {handleSetOTStopWage(offsetDayIndex, validateUsd(entry.weeks[index].stops[offsetDayIndex]))}}
                />
            </td>
        )
    });

    const thresholdColumns = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        const offsetDayIndex = (dayIndex + payrollPeriodStartOffset) % 7;

        return (
            <td key={dayIndex}>
                <Form.Control 
                    style={{padding: '2px 6px 2px 6px'}} 
                    type='number' 
                    min={0} 
                    value={entry.weeks[index].otStopWageDefinitions[offsetDayIndex]['threshold'] ?? ''} 
                    placeholder='Threshold' 
                    onChange={(event) => {handleSetOTStopWageDefinition(offsetDayIndex, 'threshold', event.target.value)}}
                    onBlur={() => {handleSetOTStopWageDefinition(offsetDayIndex, 'threshold', validateInteger(entry.weeks[index].otStopWageDefinitions[offsetDayIndex]['threshold']))}}
                />
            </td>
        )
        
    })

    const amountColumns = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        const offsetDayIndex = (dayIndex + payrollPeriodStartOffset) % 7;

        return (
            <td key={dayIndex}>
                <InputGroup>
                    <InputGroup.Text style={{padding: '0px 6px 0px 6px'}}>$</InputGroup.Text>
                    <Form.Control 
                        style={{padding: '2px 6px 2px 6px'}} 
                        type='number' 
                        min={0} 
                        value={entry.weeks[index].otStopWageDefinitions[offsetDayIndex]['amount'] ?? ''} 
                        placeholder='Amount' 
                        onChange={(event) => {handleSetOTStopWageDefinition(offsetDayIndex, 'amount', event.target.value)}}
                        onBlur={() => {handleSetOTStopWageDefinition(offsetDayIndex, 'amount', validateDecimal(entry.weeks[index].otStopWageDefinitions[offsetDayIndex]['amount']))}}
                    />
                </InputGroup>
            </td>
        )
    })

    const bonusColumns = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        const offsetDayIndex = (dayIndex + payrollPeriodStartOffset) % 7;

        const stops = entry.weeks[index].stops[offsetDayIndex] ?? 0;
        const threshold = entry.weeks[index].otStopWageDefinitions[offsetDayIndex].threshold;
        const amount = entry.weeks[index].otStopWageDefinitions[offsetDayIndex].amount;

        return (
            <td key={dayIndex}>
                {stops && threshold && amount && stops > threshold ? 
                    <span style={{color: 'green'}}>{usdFormatter.format((stops - threshold) * amount)}</span>
                :
                    <span style={{opacity: 0.5}}>None</span>
                }
            </td>
        )
    })
    
    const stopRow = (
        <tr key='stopRow' style={{verticalAlign: 'middle'}}>
            <td>Stops</td>
            {stopColumns}
        </tr>
    )

    const thresholdRow = (
        <tr key='thresholdRow' style={{verticalAlign: 'middle'}}>
            <td>Stop Threshold</td>
            {thresholdColumns}
        </tr>
    )

    const amountRow = (
        <tr key='amountRow' style={{verticalAlign: 'middle'}}>
            <td>$ Amount / Stop</td>
            {amountColumns}
        </tr>
    )

    const bonusRow = (
        <tr key='bonusRow' style={{verticalAlign: 'middle'}}>
            <td>OT Stop Wages</td>
            {bonusColumns}
        </tr>
    )

    
    const headers = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        return moment(entry.periodStart).add(index, 'weeks').add(dayIndex, 'days').format('ddd D')
    })

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <Card.Title style={{margin: 0}}>Over-Threshold Stop Wages</Card.Title>
                    <FontAwesomeIcon className="cursor-pointer" onClick={() => {setShowTooltip(true)}} style={{color: 'var(--bs-primary)'}} icon={faQuestionCircle}/>
                </div>
                <div style={{display: 'flex', gap: 6}}>
                    <Button variant="outline-primary" onClick={() => {setShowDaysWorkedModal(true)}}>Recalculate Days Worked</Button>
                    <Button variant="outline-primary" onClick={() => {setShowModal(true)}}>Recalculate Stop Wages</Button>
                </div>
            </div>
            <QuickTable headers={['', ...headers]} noMargin widths={['12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%']} rows={[stopRow, thresholdRow, amountRow, bonusRow]} size={'sm'}/>
            <div style={{display: 'flex', gap: 6, fontWeight: 'bold', padding: 6, marginBottom: 12}}>
                <span>Total Over-Threshold Stop Wages</span>
                <div style={{flex: 1, borderBottom: '2px dotted lightgray', marginBottom: 6}}/>
                <span style={{color: 'green'}}>{bigToUsd(entry.weeks[index].totalOTStopWages())}</span>
            </div>
            <Modal show={showModal} onHide={() => {setShowModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recalculate Stop Wages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold', marginRight: 8}}/>
                    Upon recalculating an employee's stop wages, any preexisting stops & stop wages will be deleted and recreated based upon the uploaded settlement data.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => {setShowModal(false); handleRecalculateStopWages();}}>Proceed</Button>
                </Modal.Footer>

            </Modal>
            <Modal show={showDaysWorkedModal} onHide={() => {setShowDaysWorkedModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recalculate Days Worked From Settlement Report Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold', marginRight: 8}}/>
                    Upon recalculating an employee's days worked, any preexisting daily wages will be deleted and recreated based upon the uploaded settlement data.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => {setShowDaysWorkedModal(false); handleRecalculateDaysWorked();}}>Proceed</Button>
                </Modal.Footer>

            </Modal>
            <Modal show={showTooltip} onHide={() => {setShowTooltip(false)}} centered size="lg">
                <Modal.Header>
                    <Modal.Title>Over-Threshold Stop Wages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li><b>Stops</b>: How many stops an employee made on this day. These fields are prefilled with the uploaded Settlement Report data for the employee.</li>
                        <li><b>Stop Threshold</b>: If the number of stops exceeds this threshold, the employee will receive additional compensation for each stop beyond the threshold. These fields are prefilled with the weekday and weekend stop thresholds defined in the employee's pay.</li>
                        <li><b>$ Amount / Stop</b>: The dollar amount an employee receives for each stop beyond the stop threshold. These fields are prefilled with the weekday and weekend stop wage dollar amounts defined in the employee's pay.</li>
                    </ul>
                    <p>An employee's stops and stop wages can be recalculated at any time by clicking the <b>Recalculate Stop Wages</b> button.</p>
                    <p style={{fontWeight: 'bold'}}>Over-Threshold Stop Wages are nondiscretionary bonuses which are incorporated into the employee's FLSA overtime rate.</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

