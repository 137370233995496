/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../components/CustomButton.js';
import QuickTable from '../../components/QuickTable.js';

type Props = {
  checks: any[];
  payComponents: any;
  handleSubmit: () => void;
  isSubmitting: boolean;
};

export default function PaychexPayrollSubmissionPreview({ checks, payComponents, handleSubmit, isSubmitting }: Props) {
  const checkElements = useMemo(() => {
    return checks.map((check: any) => {
      const earnings = (check.earnings as any[]).map((earning, index) => {
        const ttaKeys = Object.keys(payComponents)
          .filter((key) => payComponents[key] == earning.componentId)
          .join(', ');

        return (
          <tr key={index}>
            <td>{ttaKeys}</td>
            <td>{earning.componentId}</td>
            <td>{earning.payAmount ?? ''}</td>
            <td>{earning.payRate ?? ''}</td>
            <td>{earning.payUnits ?? ''}</td>
            <td>{earning.payHours ?? ''}</td>
          </tr>
        );
      });

      return (
        <div
          key={check.workerId ?? check.companyUserIdentifier}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            border: '1px solid lightgray',
            borderRadius: 6,
            padding: 6,
          }}
        >
          <span style={{ fontSize: 20, fontWeight: 'bold' }}>{`${check.lastName}, ${check.firstName} (${
            check.workerId ?? 'MISSING ID'
          })`}</span>

          <QuickTable
            headers={['TTA Key(s)', 'Paychex Component ID', 'Flat Amount', 'Pay Rate', 'Pay Units', 'Pay Hours']}
            rows={earnings}
          />
        </div>
      );
    });
  }, [checks, payComponents]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Paychex Payroll Submission Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>{checkElements}</Modal.Body>
      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
        {checks.some((c) => !c.workerId) && (
          <span style={{ fontSize: 13, color: 'red' }}>
            One or more Employee is missing a Paychex Worker ID. Please match Paychex Workers to the employees included in this
            payroll period.
          </span>
        )}
        <CustomButton
          onClick={handleSubmit}
          style={{ whiteSpace: 'nowrap' } as any}
          isLoading={isSubmitting}
          label="Submit Payroll"
          disabled={checks.some((c) => !c.workerId)}
        />
      </Modal.Footer>
    </>
  );
}
