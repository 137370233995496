/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ApiRequest } from '../../ApiManager.tsx';
import DropdownControl from '../../components/DropdownControl';
import CustomButton from '../../components/CustomButton.js';
import LoadingWrapper from '../../components/LoadingWrapper.js';

export default function MatchCompanyPaychexPayComponents() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanyIdentifier, setSelectedCompanyIdentifier] = useState<number>();

  const [payComponents, setPayComponents] = useState({});
  const [paychexPayComponents, setPaychexPayComponents] = useState<any[]>([]);

  const handleSetPayComponent = useCallback(
    (key: string, payComponentId: string) => {
      setPayComponents({ ...payComponents, [key]: payComponentId });
    },
    [payComponents, setPayComponents]
  );

  const loadCompanies = useCallback(() => {
    new ApiRequest('admin', 'getCompaniesWithPaychexAccounts', setIsLoading, (response) => {
      setCompanies(response.companies);
    })
      .withNoAlertOnSuccess()
      .send();
  }, [setIsLoading, setCompanies]);

  useEffect(loadCompanies, []);

  const loadData = useCallback(() => {
    new ApiRequest('admin', 'getCompanyPaychexPayComponents', setIsLoading, (response) => {
      setPayComponents(response.payComponents);
      setPaychexPayComponents(response.paychexPayComponents);
    })
      .withData({ companyIdentifier: selectedCompanyIdentifier })
      .withNoAlertOnSuccess()
      .send();
  }, [selectedCompanyIdentifier, setIsLoading, setPayComponents, setPaychexPayComponents]);

  useEffect(() => {
    if (!!selectedCompanyIdentifier) {
      loadData();
    }
  }, [selectedCompanyIdentifier]);

  const handleSubmit = useCallback(() => {
    new ApiRequest('admin', 'updateCompanyPaychexPayComponents', setIsSubmitting, (response) => {
      
    })
      .withData({ companyIdentifier: selectedCompanyIdentifier, payComponents: payComponents })
      .send();
  }, [selectedCompanyIdentifier, payComponents, setIsSubmitting, loadData]);

  const payComponentElements = [
    'yearlyWages',
    'dailyWages',
    'hourlyWages',
    // 'dailyServiceBonusWages',
    'overtimeWages',
    'stopWages',
    'mileWages',
    'extraDayWages',
    'autoOvertimeWages',
    'ptoWages',
    'holidayWages',
    'ndBonus_bonus',
    'ndBonus_safetyBonus',
    'ndBonus_serviceBonus',
    'ndBonus_travelWage',
    'ndBonus_overThresholdStopWage',
    'ndBonus_smallTruckVariance',
    // 'ndBonus_referralBonus',
    'dBonus_holidayBonus',
    'dBonus_other',
    'ap_perDiem',
    'ap_ExpenseReimbursement',
    'ap_extraDayWage',
    'ap_backPay',
    // 'ap_incentiveWage',
    // 'ap_standByWage',
    // 'ap_other',
  ].map((key) => {

    const match = payComponents?.[key];

    return (
        <DropdownControl 
          key={key}
          title={key}
          itemNames={paychexPayComponents.map((c) => `${c.name} | ${c.description} | ${c.classificationType} | ${c.effectOnPay}`)}
          itemValues={paychexPayComponents.map((c) => c.componentId)}
          selection={match}
          setSelection={(componentId: any) => {
            handleSetPayComponent(key, componentId);
          }}
        />
    )
  })

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Match Company Paychex Pay Components</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
        <LoadingWrapper isLoading={isLoading}>
          <DropdownControl
            title="TTA Company"
            itemNames={companies.map((c) => c.companyName)}
            itemValues={companies.map((c) => c.uid)}
            selection={selectedCompanyIdentifier}
            setSelection={setSelectedCompanyIdentifier}
          />

          <hr/>

          { selectedCompanyIdentifier && 
            <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
              {payComponentElements}
            </div>
          }
          
        </LoadingWrapper>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton label="Submit" isLoading={isSubmitting} onClick={handleSubmit} />
      </Modal.Footer>
    </>
  );
}
