/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { Modal } from 'react-bootstrap';
import AcknowledgementModal from './views/AcknowledgementModal';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import NotificationModal from './views/NotificationModal';
import { ToastContainer } from 'react-toastify';
import ApiManager, { ApiRequest } from './ApiManager.tsx';
import Cookies from 'universal-cookie';
import LoadingWrapper from './components/LoadingWrapper.js';
import ApiTimeoutModal from './views/ApiTimeoutModal.js';

export default function App() {
    const contentRef = useRef();

    const [isLoading, setIsLoading] = useState(true);

    const [auth, setAuth] = useState(null);
    const [showTimeout, setShowTimeout] = useState(false);

    const [showOffCanvas, setShowOffCanvas] = useState(true);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const [initializedCobrowse, setInitializedCobrowse] = useState(false);

    const [company, setCompany] = useState({});
    const [companies, setCompanies] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        ApiManager.initGlobalInstance(setAuth, setShowTimeout);
        handleCheckAuthentication();
    }, [])

    const handleCheckAuthentication = () => {
        new ApiRequest('authentication', 'checkAuthentication', setIsLoading, (response) => {
            const cookies = new Cookies();
            if (response.auth.companyUserIdentifier) {
                cookies.set('permissions', response.permissions);
                cookies.set('userData', response.userData);
                cookies.set('restrictBcsToCsa', response.restrictBcsToCsa);
                cookies.set('restrictWhoSeesPay', response.restrictWhoSeesPay);
                cookies.set('companyName', response.companies.find(c => c.uid == response.userData.companyIdentifier)?.companyName ?? '');
                cookies.set('company', response.company);
                cookies.set('auth', response.auth);
        
                setCompany(response.company);
                setCompanies(response.companies);
                setDocuments(response.documents ?? []);
                setNotifications(response.notifications ?? []);
                setPermissions(response.permissions);

                if (response.documents?.length > 0) {
                    setShowDocumentModal(true);
                }
                if (response.notifications?.length > 0) {
                    setShowNotification(true);
                }
            }

            if (!initializedCobrowse) {
                initializeCobrowse(response.userData, response.auth);
            }
            setAuth(response.auth);
        }).withNoAlertOnSuccess().send();
    }


    const initializeCobrowse = (userData, auth) => {
        setInitializedCobrowse(true);

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `(function(w,t,c,p,s,e){p=new Promise(function(r){w[c]={client:function(){if(!s){
          s=document.createElement(t);s.src='https://js.cobrowse.io/CobrowseIO.js';s.async=1;s.crossOrigin='anonymous';
          e=document.getElementsByTagName(t)[0];e.parentNode.insertBefore(s,e);s.onload=function()
          {r(w[c]);};}return p;}};});})(window,'script','CobrowseIO');
    
          CobrowseIO.license = "h-pVfTMTlbqNvQ";
          CobrowseIO.customData = {
            user_id: "${auth.companyUserIdentifier ?? auth.adminIdentifier ?? 'UNKNOWN'}",
            user_name: "${userData?.firstName ?? 'UNKNOWN'} ${userData?.lastName ?? 'ERR'} ${!!auth?.adminIdentifier ? '(ADMIN)' : ''}",
            device_name: "TC 1.0 - ${userData?.firstName ?? 'UNKNOWN'} ${userData?.lastName ?? 'UNKNOWN'} ${!!auth?.adminIdentifier ? '(ADMIN)' : ''}"
          };
          CobrowseIO.client().then(function(){
              CobrowseIO.start();
          });`;
        document.body.appendChild(s);
    }
    
    return (
        <div className="App"> {/* Navigation bar rendered second with row-reverse so that shadow renders on top of outlet */}
            <LoadingWrapper isLoading={isLoading}>
                <BrowserRouter>
                    <div ref={contentRef} className={'cwt-in'} style={{flex: 1,  height: '100vh', display: 'flex', flexDirection: 'column', position: 'relative'}}> 
                        {/* <TopNavigationBar/> */}
                        <div style={{height: '100vh'}}>
                            <Router auth={auth} permissions={permissions} handleCheckAuthentication={handleCheckAuthentication}/>
                        </div>
                        { auth?.companyUserIdentifier && 
                            <Button style={{color:'white', position: 'absolute', zIndex: 1, bottom: '45%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, padding: 2, height: 100}} onClick={() => {setShowOffCanvas(!showOffCanvas)}}>
                                <FontAwesomeIcon icon={showOffCanvas ? faCaretLeft : faCaretRight}/>
                            </Button>
                        }
                    </div>
                    { auth?.companyUserIdentifier && 
                        <CSSTransition nodeRef={contentRef} in={showOffCanvas} timeout={250} classNames='my-node' unmountOnExit>
                            <div ref={contentRef}>
                                <NavigationBar auth={auth} company={company} companies={companies} handleCheckAuthentication={handleCheckAuthentication}/>
                            </div>
                        </CSSTransition>
                    }
                </BrowserRouter>
            </LoadingWrapper>
            <Modal show={showDocumentModal} onHide={() => {setShowDocumentModal(false)}} fullscreen backdrop='static'>
                <AcknowledgementModal documents={documents} hideModal={() => {setShowDocumentModal(false)}}/>
            </Modal>
            <Modal show={showNotification} onHide={() => {setShowNotification(false)}} size='xl' backdrop='static'>
                <NotificationModal notifications={notifications} hideNotifications={() => {setShowNotification(false)}}/>
            </Modal>
            <Modal show={showTimeout} onHide={() => {setShowTimeout(false)}} centered backdrop='static'>
                <ApiTimeoutModal hideModal={() => {setShowTimeout(false)}}/>
            </Modal>
            <ToastContainer toastStyle={{fontSize: 14}} autoClose={3000}/>
        </div>
    );
}