/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from 'big.js';

export const adminMode = false;

export const payTypeDictionary = {
  ph: 'Per Hour',
  pd: 'Per Day',
  py: 'Per Year',
  pm: 'Per Mile',
  ps: 'Per Stop',
  xd: 'Extra Day Wage',
  iw: 'Incentive Wage',
  sw: 'Stand-By Wage',
};
export const payTypeDictionaryWithOt = {
  ph: 'Per Hour',
  pd: 'Per Day',
  py: 'Per Year',
  pm: 'Per Mile',
  ps: 'Per Stop',
  xd: 'Extra Day Wage',
  iw: 'Incentive Wage',
  sw: 'Stand-By Wage',
  ot: 'Overtime',
};
export const payRateDictionary = {
  ph: 'Hourly Rate',
  pd: 'Daily Rate',
  py: 'Salary',
  pm: 'Pay Per Mile',
  ps: 'Pay Per Stop',
  xd: 'Extra Day Rate',
};
export const wageDictionary = {
  ph: 'Hourly Wages',
  pd: 'Regular Wages',
  py: 'Regular Wages',
  pm: 'Mile Wages',
  ps: 'Stop Wages',
  xd: 'Extra Day Wages',
};

export const unitsWorkedDictionary = {
  ph: 'Hours Worked',
  pd: 'Days Worked',
  pm: 'Miles',
  ps: 'Stops',
  xd: 'Extra Days Worked',
};

export const holidayPayTypeDictionary = {
  ph: 'Per Hour',
  pd: 'Per Day',
  flat: 'Flat Amount',
};

export function getUnitsWorkedTypeString(payType) {
  if (payType === 'ph') {
    return 'Hours Worked';
  } else if (payType === 'pm') {
    return 'Number of Miles';
  } else if (payType === 'ps') {
    return 'Number of Stops';
  } else if (payType === 'pd' || payType === 'xd') {
    return 'Days Worked';
  } else if (payType === 'py') {
    return 'Yearly';
  } else if (payType === 'iw' || payType === 'sw' || payType === 'ot') {
    return 'Flat Amount';
  }
}

export const employeeTypeDictionary = {
  0: 'Full-Time',
  1: 'Part-Time',
};

export function validateDecimal(value) {
  return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateInteger(value) {
  return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function validateBig(value, defaultValue = new Big('0.0')) {
  try {
    return new Big(value);
  } catch {
    return defaultValue;
  }
}

export function bigToUsd(value) {
  return '$' + value.toFixed(2);
}

export function validateUsd(value) {
  return isNaN(parseFloat(value)) ? 0.0 : parseFloat(parseFloat(value).toFixed(2));
}

export function toFixedMax(number, decimalPlaces) {
  return parseFloat(parseFloat(number).toFixed(decimalPlaces));
}

export function generatePaychexJson(entries, payComponents, users) {
  return entries
    .map((e) => {

      const paychexId = users.find(u => u.companyUserIdentifier == e.companyUserIdentifier)?.paychexId;

      return {
        firstName: e.firstName,
        lastName: e.lastName,
        companyUserIdentifier: e.companyUserIdentifier,
        workerId: paychexId,
        checkCorrelationId: e.companyUserIdentifier.toString(),
        earnings: generatePaychexEarningsForEntry(e, payComponents),
      };
    })
    .filter((check) => check.earnings.length > 0);
}

function generatePaychexEarningsForEntry(entry, payComponents) {
  let earnings = [];

  if (!!payComponents['yearlyWages']) {
    earnings = earnings.concat(entry.paychexSalaryEarnings(payComponents['yearlyWages']));
  }

  if (!!payComponents['dailyWages']) {
    earnings = earnings.concat(entry.paychexDailyEarnings(payComponents['dailyWages']));
  }

  if (!!payComponents['hourlyWages']) {
    earnings = earnings.concat(entry.paychexHourlyEarnings(payComponents['hourlyWages']));
  }

  // if (!!payComponents['dailyServiceBonusWages']) {
  //   earnings = earnings.concat(entry.getPaychex);
  // }

  if (!!payComponents['overtimeWages']) {
    earnings = earnings.concat(entry.paychexOvertimeEarnings(payComponents['overtimeWages']));
  }

  if (!!payComponents['stopWages']) {
    earnings = earnings.concat(entry.paychexStopWageEarnings(payComponents['stopWages']));
  }

  if (!!payComponents['mileWages']) {
    earnings = earnings.concat(entry.paychexMileWageEarnings(payComponents['mileWages']));
  }

  if (!!payComponents['extraDayWages']) {
    earnings = earnings.concat(entry.paychexExtraDayWageEarnings(payComponents['extraDayWages']));
  }

  if (!!payComponents['autoOvertimeWages']) {
    earnings = earnings.concat(entry.paychexAutoOvertimeWageEarnings(payComponents['autoOvertimeWages']));
  }

  if (!!payComponents['ptoWages']) {
    earnings = earnings.concat(entry.paychexPtoEarnings(payComponents['ptoWages']));
  }

  if (!!payComponents['holidayWages']) {
    earnings = earnings.concat(entry.paychexHolidayEarnings(payComponents['holidayWages']));
  }

  if (!!payComponents['ndBonus_bonus']) {
    earnings = earnings.concat(entry.paychexNDBonusEarningsOfType('Bonus', payComponents['ndBonus_bonus']));
  }

  if (!!payComponents['ndBonus_safetyBonus']) {
    earnings = earnings.concat(entry.paychexNDBonusEarningsOfType('Safety Bonus', payComponents['ndBonus_safetyBonus']));
  }

  if (!!payComponents['ndBonus_serviceBonus']) {
    earnings = earnings.concat(entry.paychexNDBonusEarningsOfType('Service Bonus', payComponents['ndBonus_serviceBonus']));
  }

  if (!!payComponents['ndBonus_travelWage']) {
    earnings = earnings.concat(entry.paychexNDBonusEarningsOfType('Travel Wage', payComponents['ndBonus_travelWage']));
  }

  if (!!payComponents['ndBonus_overThresholdStopWage']) {
    earnings = earnings.concat(entry.paychexNDBonusEarningsOfType('Over-Threshold Stop Wage', payComponents['ndBonus_overThresholdStopWage']));
  }

  if (!!payComponents['ndBonus_smallTruckVariance']) {
    earnings = earnings.concat(entry.paychexNDBonusEarningsOfType('Small Truck Variance', payComponents['ndBonus_smallTruckVariance']));
  }

  // if (!!payComponents['ndBonus_referralBonus']) {
  //   earnings = earnings.concat(entry.paychexNDBonusEarningsOfType('', payComponents['ndBonus_referralBonus']));
  // }

  if (!!payComponents['dBonus_holidayBonus']) {
    earnings = earnings.concat(entry.paychexDBonusEarningsOfType('Holiday Bonus', payComponents['dBonus_holidayBonus']));
  }

  if (!!payComponents['dBonus_other']) {
    earnings = earnings.concat(entry.paychexDBonusEarningsOfType('Other Discretionary Bonus', payComponents['dBonus_other']));
  }

  if (!!payComponents['ap_perDiem']) {
    earnings = earnings.concat(entry.paychexAdditionalPayEarningsOfType('Per Diem', payComponents['ap_perDiem']));
  }

  if (!!payComponents['ap_ExpenseReimbursement']) {
    earnings = earnings.concat(entry.paychexAdditionalPayEarningsOfType('Expense Reimbursement', payComponents['ap_ExpenseReimbursement']));
  }

  if (!!payComponents['ap_extraDayWage']) {
    earnings = earnings.concat(entry.paychexAdditionalPayEarningsOfType('Extra Day Wage', payComponents['ap_extraDayWage']));
  }

  if (!!payComponents['ap_backPay']) {
    earnings = earnings.concat(entry.paychexAdditionalPayEarningsOfType('Backpay', payComponents['ap_backPay']));
  }

  if (!!payComponents['ap_incentiveWage']) {
    earnings = earnings.concat(entry.paychexIncentiveWageEarnings(payComponents['ap_incentiveWage']));
  }

  if (!!payComponents['ap_standByWage']) {
    earnings = earnings.concat(entry.paychexStandByWageEarnings(payComponents['ap_standByWage']));
  }

  // if (!!payComponents['ap_other']) {
  //   earnings = earnings.concat(entry.paychexAdditionalPayEarningsOfType('', payComponents['ap_other']));
  // }

  return earnings;
}
