/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import CustomControl from "../../components/CustomControl";
import SwitchControl from "../../components/SwitchControl";
import Form from 'react-bootstrap/Form';
import { validateDecimalFixed, validateInteger } from "../../tools";
import { Dropdown, InputGroup, ListGroup, Modal } from "react-bootstrap";
import KeyValueRow from "../../components/KeyValueRow";
import MyCompanyRequestOffBlackoutDates from "./MyCompanyRequestOffBlackoutDates";
import MyCompanyOfferLetters from "./MyCompanyOfferLetters";
import MyCompanyIncentiveWages from "./MyCompanyIncentiveWages";
import LoadingWrapper from "../../components/LoadingWrapper";
import DropdownItemWithCheck from "../../components/DropdownItemWithCheck";
import { ApiRequest } from "../../ApiManager.tsx";
import CustomButton from "../../components/CustomButton.js";

export default function MyCompanySettings({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [settings, setSettings] = useState({});
    const [originalSettings, setOriginalSettings] = useState({});
    const [offerLetters, setOfferLetters] = useState([]);
    const [modalSwitch, setModalSwitch] = useState('');
    
    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getSettings', setIsLoading, (response) => {
            setSettings({...response.settings, autoOvertimeAmount: response.settings.autoOvertimeAmount * 100});
            setOriginalSettings({...response.settings, autoOvertimeAmount: response.settings.autoOvertimeAmount * 100})
            setOfferLetters(response.offerLetters);
        }).withNoAlertOnSuccess().send();
    }

    function handleSaveSettings(){
        new ApiRequest('myCompany', 'updateSettings', setIsUpdating, () => setOriginalSettings(structuredClone(settings)))
        .withData({
            settings: {
                ...settings, 
                autoOvertimeAmount: validateDecimalFixed(settings.autoOvertimeAmount / 100, 3),
                clockInReminder: settings.clockInReminder ? settings.clockInReminder : null
            }
        })
        .send();
    }

    function handleSetSettings(key, value) {
        const newSettings = structuredClone(settings);
        newSettings[key] = value;
        setSettings(newSettings)
    }

    function hideModal(){
        setModalSwitch('')
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 16, padding: 6}}>
                <SwitchControl 
                    title='Include company address as a valid clock-in location for employees' 
                    originalValue={originalSettings.companyAddressAllowClockIn}
                    value = {settings.companyAddressAllowClockIn}
                    setValue = {(value) => {handleSetSettings('companyAddressAllowClockIn', value)}}
                />
                { settings.companyAddressAllowClockIn == 1 &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                        <CustomControl
                            maxDecimal={1000000}
                            min={0}
                            type='number' 
                            title='Company address clock-in radius (in miles)' 
                            originalValue={originalSettings.companyAddressClockInRadius} 
                            value={settings.companyAddressClockInRadius} 
                            onChange={(event) => {handleSetSettings('companyAddressClockInRadius', event.target.value)}} 
                        />
                    </div>
                }
                <CustomControl
                    type='number' 
                    title='Set number of days drivers can see when viewing their schedule in TTA App'
                    min={0}
                    maxInt={365}
                    originalValue={originalSettings.driverSchedulePreviewDays} 
                    value={settings.driverSchedulePreviewDays} 
                    onChange={(event) => {handleSetSettings('driverSchedulePreviewDays', event.target.value)}} 
                />
                <SwitchControl
                    title='Send employees a text reminder to clock in after their shift starts if they are not already clocked in'
                    originalValue={originalSettings.clockInReminder != null}
                    value={settings.clockInReminder != null} 
                    setValue={() => {handleSetSettings('clockInReminder', settings.clockInReminder || settings.clockInReminder === '' ? null : '')}}
                />
                { settings.clockInReminder != null && 
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                        <CustomControl
                            type='time' 
                            title='Time of day which clock-in reminders will be sent out to employees' 
                            originalValue={originalSettings.clockInReminder} 
                            value={settings.clockInReminder} 
                            onChange={(event) => {handleSetSettings('clockInReminder', event.target.value)}} 
                        />
                    </div>
                }
                <SwitchControl
                    title='Send AO and BCs a text when a new employee logs in for the first time'
                    originalValue={originalSettings.sendFirstLoginText}
                    value={settings.sendFirstLoginText} 
                    setValue={(value) => {handleSetSettings('sendFirstLoginText', value)}}
                />
                <SwitchControl
                    title='Allow business contacts to access sensitive information ( this will force MFA for business contacts)'
                    originalValue={originalSettings.allowBCAccessToSensitiveInformation}
                    value={settings.allowBCAccessToSensitiveInformation} 
                    setValue={(value) => {handleSetSettings('allowBCAccessToSensitiveInformation', value)}}
                />
                <SwitchControl
                    title='Force all employees to use MFA'
                    originalValue={originalSettings.forceMfa}
                    value={settings.forceMfa}
                    setValue={(value)=>{handleSetSettings('forceMfa', value)}} 
                />
                <SwitchControl
                    title='Require employees to provide at least one emergency contact when onboarding'
                    originalValue={originalSettings.require1EContact}
                    value={settings.require1EContact}
                    setValue={(value)=>{handleSetSettings('require1EContact', value)}} 
                />
                <SwitchControl 
                    title='Force employees to enable location services when clocking in and out'
                    originalValue={originalSettings.forceLocation}
                    value={settings.forceLocation}
                    setValue={(value)=>{handleSetSettings('forceLocation', value)}} 
                />
                { settings.forceLocation == 1 &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                        <SwitchControl 
                            title='Enable Geofencing (require employees to be within radius of CSA to clock in)'
                            originalValue={originalSettings.enableGeofencing}
                            value={settings.enableGeofencing}
                            setValue={(value)=>{handleSetSettings('enableGeofencing', value)}} 
                        />
                    </div>
                }

                <SwitchControl 
                    title={'Only allow AOs and Admins to view the pay of AOs, Admins, and BCs on the Human Resources page'}
                    originalValue={originalSettings.restrictWhoSeesPay}
                    value={settings.restrictWhoSeesPay}
                    setValue={(value)=>{handleSetSettings('restrictWhoSeesPay', value)}} 
                />
                { settings.payrollEnabled == 1 &&
                    <SwitchControl 
                        title={'Include AOs in payroll by default'}
                        originalValue={originalSettings.includeAOsInPayroll}
                        value={settings.includeAOsInPayroll}
                        setValue={(value)=>{handleSetSettings('includeAOsInPayroll', value)}} 
                    />
                }
                { settings.payrollEnabled == 1 &&
                    <SwitchControl 
                        title={'Show BCs Payroll History'}
                        originalValue={originalSettings.showBcsPayrollHistory}
                        value={settings.showBcsPayrollHistory}
                        setValue={(value)=>{handleSetSettings('showBcsPayrollHistory', value)}} 
                    />
                }
                { settings.payrollEnabled == 1 &&
                    <SwitchControl 
                        title={'Allow time off approval as PTO with negative PTO balance'}
                        originalValue={originalSettings.allowNegativePto}
                        value={settings.allowNegativePto}
                        setValue={(value)=>{handleSetSettings('allowNegativePto', value)}} 
                    />
                }
                { settings.payrollEnabled == 1 && settings.restrictBcsToCsa == 1 && 
                    <SwitchControl 
                        title={'Allow Restricted BCs to include drivers from other CSAs when running payroll'}
                        originalValue={originalSettings.allowRestrictedBcsAccessToDriversFromOtherCsas}
                        value={settings.allowRestrictedBcsAccessToDriversFromOtherCsas}
                        setValue={(value)=>{handleSetSettings('allowRestrictedBcsAccessToDriversFromOtherCsas', value)}} 
                    />
                }
                <SwitchControl 
                    title='Limit the number of employees who can request time off on a given day (company-wide)'
                    originalValue={originalSettings.requestOffPerDayLimit ?? false}
                    value={settings.requestOffPerDayLimit ?? false}
                    setValue={(value)=>{handleSetSettings('requestOffPerDayLimit', settings.requestOffPerDayLimit == null ? 1 : null)}} 
                />
                { settings.requestOffPerDayLimit != null &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                        <span style={{fontSize: 13, opacity: 0.6}}>{'Maximum number of employees who can request time off on a given day'}</span>
                        <div style={{display: 'flex', gap: 8, border: '1px solid lightgray', borderRadius: 6, alignItems: 'center', maxWidth: 600, paddingRight: 6}}>
                            <div style={{padding: 6, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', borderRight: '1px solid lightgray'}}>
                                <span style={{whiteSpace: 'nowrap', color: 'white'}}>{`${settings.requestOffPerDayLimit} Employee${settings.requestOffPerDayLimit > 1 ? 's' : ''}`}</span>
                            </div>
                            <Form.Range style={{}} min={1} max={9} value={settings.requestOffPerDayLimit} onChange={(event) => {handleSetSettings('requestOffPerDayLimit', validateInteger(event.target.value))}}/>
                        </div>

                    </div>
                }
                <SwitchControl 
                    title='Limit the number of employees who can request time off on a given day in each CSA'
                    originalValue={originalSettings.requestOffPerDayPerCsaLimit ?? false}
                    value={settings.requestOffPerDayPerCsaLimit ?? false}
                    setValue={(value)=>{handleSetSettings('requestOffPerDayPerCsaLimit', settings.requestOffPerDayPerCsaLimit == null ? 1 : null)}} 
                />
                { settings.requestOffPerDayPerCsaLimit != null &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                        <span style={{fontSize: 13, opacity: 0.6}}>{'Maximum number of employees who can request time off on a given day'}</span>
                        <div style={{display: 'flex', gap: 8, border: '1px solid lightgray', borderRadius: 6, alignItems: 'center', maxWidth: 600, paddingRight: 6}}>
                            <div style={{padding: 6, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', borderRight: '1px solid lightgray'}}>
                                <span style={{whiteSpace: 'nowrap', color: 'white'}}>{`${settings.requestOffPerDayPerCsaLimit} Employee${settings.requestOffPerDayPerCsaLimit > 1 ? 's' : ''}`}</span>
                            </div>
                            <Form.Range style={{}} min={1} max={9} value={settings.requestOffPerDayPerCsaLimit} onChange={(event) => {handleSetSettings('requestOffPerDayPerCsaLimit', validateInteger(event.target.value))}}/>
                        </div>

                    </div>
                }
                <SwitchControl 
                    title='Automatically reward employees with an incentive wage if they work extra days'
                    originalValue={originalSettings.autoIncentiveWageThreshold != null}
                    value={settings.autoIncentiveWageThreshold != null}
                    setValue={(value)=>{handleSetSettings('autoIncentiveWageThreshold', settings.autoIncentiveWageThreshold == null ? 5 : null)}} 
                />
                { settings.autoIncentiveWageThreshold != null &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                        <InputGroup>
                            <CustomControl type='number' min={0} maxInt={6} value={settings.autoIncentiveWageThreshold} originalValue={originalSettings.autoIncentiveWageThreshold} onChange={(event) => {handleSetSettings('autoIncentiveWageThreshold', event.target.value)}} title='Automatic Incentive Wage Threshold'/>
                            <InputGroup.Text>$</InputGroup.Text>
                            <CustomControl type='number' min={0} maxDecimal={999.99} value={settings.autoIncentiveWageAmount} originalValue={originalSettings.autoIncentiveWageAmount} onChange={(event) => {handleSetSettings('autoIncentiveWageAmount', event.target.value)}} title='Automatic Incentive Wage Amount'/>
                        </InputGroup>
                        <span style={{fontSize: 14, opacity: 0.66}}>The threshold defines the number of days in a week which an employee must <b>exceed</b> in order to receive an incentive wage. The employee will receive the incentive wage amount for <b>each day</b> which they work beyond this threshold.</span>
                    </div>
                }

                <SwitchControl 
                    title='Automatically reward employees with overtime if they work extra days'
                    originalValue={originalSettings.autoOvertimeThreshold != null}
                    value={settings.autoOvertimeThreshold != null}
                    setValue={(value)=>{handleSetSettings('autoOvertimeThreshold', settings.autoOvertimeThreshold == null ? 5 : null)}} 
                />
                { settings.autoOvertimeThreshold != null &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray', marginBottom: 12}}>
                        <InputGroup>
                            <CustomControl type='number' min={0} maxInt={6} value={settings.autoOvertimeThreshold} originalValue={originalSettings.autoOvertimeThreshold} onChange={(event) => {handleSetSettings('autoOvertimeThreshold', event.target.value)}} title='Automatic Overtime Threshold'/>
                            <InputGroup.Text>%</InputGroup.Text>
                            <CustomControl type='number' maxDecimal={999} min={0} value={settings.autoOvertimeAmount} originalValue={originalSettings.autoOvertimeAmount} onChange={(event) => {handleSetSettings('autoOvertimeAmount', event.target.value)}} title='Automatic Overtime % (Out of 100)'/>
                        </InputGroup>
                        <span style={{fontSize: 14, opacity: 0.66}}>The threshold defines the number of days in a week which an employee must <b>exceed</b> in order to receive overtime. The employee will receive overtime wages equal to their hourly and/or daily wages multipled by the percentage specified.</span>
                    </div>
                }

                <div style={{border: '1px solid lightgray', padding: 8, borderRadius: 6}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h5 style={{textAlign: 'center', marginLeft: 8}}>Offer Letters</h5>
                        <Button style={{marginBottom: 12, marginLeft: 'auto', display: 'block'}} variant={'outline-primary'} onClick={() => setModalSwitch('offerLetters')}>View/Edit Offer Letter Templates</Button>
                    </div>
                    <ListGroup>
                        {/* DRIVER DEFAULT */}
                        <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{margin: 0}}>Default Offer Letter For Driver</p>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary">
                                    {settings.defaultOfferLetterForDriver ? offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForDriver).name : 'None'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForDriver} onClick={()=>handleSetSettings('defaultOfferLetterForDriver', null)}/>
                                {offerLetters.map((ol)=>{
                                    const isSelected = ol.uid == settings.defaultOfferLetterForDriver;
                                    return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected} onClick={()=>handleSetSettings('defaultOfferLetterForDriver', ol.uid)}/>
                                })}
                                </Dropdown.Menu>
                            </Dropdown> 
                        </ListGroup.Item>      
                        {/* AVP DRIVER DEFAULT OFFER LETTER */}
                        <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{margin: 0}}>Default Offer Letter For AVP Driver</p>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary">
                                    {settings.defaultOfferLetterForAvpDriver ? offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForAvpDriver).name : 'None'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForAvpDriver} onClick={()=>handleSetSettings('defaultOfferLetterForAvpDriver', null)}/>
                                {offerLetters.map((ol)=>{
                                    const isSelected = ol.uid == settings.defaultOfferLetterForAvpDriver;
                                    return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected} onClick={()=>handleSetSettings('defaultOfferLetterForAvpDriver', ol.uid)}/>
                                })}
                                </Dropdown.Menu>
                            </Dropdown> 
                        </ListGroup.Item>      
                        {/* JUMPER DEFAULT OFFER LETTER */}
                        <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{margin: 0}}>Default Offer Letter For Jumper</p>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary">
                                    {settings.defaultOfferLetterForJumper ? offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForJumper).name : 'None'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForJumper} onClick={()=>handleSetSettings('defaultOfferLetterForJumper', null)}/>
                                {offerLetters.map((ol)=>{
                                    const isSelected = ol.uid == settings.defaultOfferLetterForJumper;
                                    return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected} onClick={()=>handleSetSettings('defaultOfferLetterForJumper', ol.uid)}/>
                                })}
                                </Dropdown.Menu>
                            </Dropdown> 
                        </ListGroup.Item>      
                        {/* BC DEFAULT OFFER LETTER */}
                        <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{margin: 0}}>Default Offer Letter For BC</p>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary">
                                    {settings.defaultOfferLetterForBc ? offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForBc).name : 'None'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForBc} onClick={()=>handleSetSettings('defaultOfferLetterForBc', null)}/>
                                {offerLetters.map((ol)=>{
                                    const isSelected = ol.uid == settings.defaultOfferLetterForBc;
                                    return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected} onClick={()=>handleSetSettings('defaultOfferLetterForBc', ol.uid)}/>
                                })}
                                </Dropdown.Menu>
                            </Dropdown> 
                        </ListGroup.Item>      
                        {/* ADMIN DEFAULT OFFER LETTER*/}
                        <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{margin: 0}}>Default Offer Letter For Admin</p>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary">
                                    {settings.defaultOfferLetterForAdmin ? offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForAdmin).name : 'None'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForAdmin} onClick={()=>handleSetSettings('defaultOfferLetterForAdmin', null)}/>
                                {offerLetters.map((ol)=>{
                                    const isSelected = ol.uid == settings.defaultOfferLetterForAdmin;
                                    return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected} onClick={()=>handleSetSettings('defaultOfferLetterForAdmin', ol.uid)}/>
                                })}
                                </Dropdown.Menu>
                            </Dropdown> 
                        </ListGroup.Item>      
                    </ListGroup>
                </div>

                <CustomButton label={'Save Changes'} variant={'outline-primary'} style={{alignSelf: 'flex-end'}} isLoading={isUpdating} onClick={handleSaveSettings}/>
                <div style={{display: 'flex', gap: 12, flexWrap: 'wrap'}}>
                    <Button variant={'outline-primary'} onClick={() => setModalSwitch('blackoutDates')}>View/Edit Request-Off Blackout Date Ranges</Button>
                    <Button variant={'outline-primary'} onClick={() => setModalSwitch('incentiveWages')}>View/Edit Custom Incentive Wages</Button>
                </div>
                <h5 style={{textAlign: 'center'}}>Contact TTA to alter any of the following settings</h5>
                <ListGroup>
                    <KeyValueRow isListItem title='Restrict BCs to their own CSAs (BCs will be responsible for submitting payroll for their own CSA and will only be able to create/edit schedules for their own CSA)' value={settings.restrictBcsToCsa == 1 ? 'Yes' : 'No'}/>
                    <KeyValueRow isListItem title='Payroll Enabled' value={settings.payrollEnabled == 1 ? 'Yes' : 'No'}/>
                    <KeyValueRow isListItem title='Payroll Period Length' value={settings.payrollPeriodLength}/>
                    <KeyValueRow isListItem title='Payroll Period Start Date' value={settings.payrollPeriodStartDate}/>
                    <KeyValueRow isListItem title='Run payroll separately for each CSA' value={settings.generatePayrollForEachCsa == 1 ? 'Yes' : 'No'}/>
                    {/* <KeyValueRow isListItem title='Allow BCs to view payroll history for their own CSA' value={settings.showBcsCSAPayrollHistory == 1 ? 'Yes' : 'No'}/> */}
                </ListGroup>
                <Modal show={modalSwitch === 'blackoutDates'} onHide={()=>hideModal()} size='lg'>
                    <MyCompanyRequestOffBlackoutDates/>
                </Modal>
                <Modal show={modalSwitch === 'offerLetters'} onHide={()=>hideModal()}>
                    <MyCompanyOfferLetters offerLetters={offerLetters} setOfferLetters={setOfferLetters}/>
                </Modal>
                <Modal show={modalSwitch === 'incentiveWages'} onHide={()=>hideModal()}>
                    <MyCompanyIncentiveWages/>
                </Modal>
            </div>
        </LoadingWrapper>
    )

}