/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";

export default class SettlementReportDay{
    uid;
    chargeStatementIdentifier;
    dayIndex;
    puPackageAmount;
    puStopAmount;
    dlPackageAmount;
    dlStopAmount;
    desigPercentage;
    desigAmount;
    ecPackageAmount;
    ecStopAmount;
    fuelAmount;
    surgeAmount;

    constructor(
        uid,
        chargeStatementIdentifier,
        dayIndex,
        puPackageAmount,
        puStopAmount,
        dlPackageAmount,
        dlStopAmount,
        desigPercentage,
        desigAmount,
        ecPackageAmount,
        ecStopAmount,
        fuelAmount,
        surgeAmount,
    ){
        this.uid = uid;
        this.chargeStatementIdentifier = chargeStatementIdentifier;
        this.dayIndex = dayIndex;
        this.puPackageAmount = puPackageAmount;
        this.puStopAmount = puStopAmount;
        this.dlPackageAmount = dlPackageAmount;
        this.dlStopAmount = dlStopAmount;
        this.desigPercentage = desigPercentage;
        this.desigAmount = desigAmount;
        this.ecPackageAmount = ecPackageAmount;
        this.ecStopAmount = ecStopAmount;
        this.fuelAmount = fuelAmount;
        this.surgeAmount = surgeAmount;
    }

    static initDefault(){
        return new SettlementReportDay(
            -1,
            -1,
            0,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        )
    }

    static initFromCsvRow(row, startDate){
        const dayIndex = moment(row[0]).diff(moment(startDate), 'days');

        return new SettlementReportDay (
            -1,
            -1,
            dayIndex,
            row[2],
            row[4],
            row[6],
            row[8],
            row[12] ?? 0,
            row[13] ?? 0,
            row[15] ?? 0,
            row[17] ?? 0,
            row[10] ?? 0,
            row[11] ?? 0,
        )
    }

    encode(){
        return {
            uid: this.uid,
            chargeStatementIdentifier: this.chargeStatementIdentifier,
            dayIndex: this.dayIndex,
            puPackageAmount: this.puPackageAmount,
            puStopAmount: this.puStopAmount,
            dlPackageAmount: this.dlPackageAmount,
            dlStopAmount: this.dlStopAmount,
            desigPercentage: this.desigPercentage,
            desigAmount: this.desigAmount,
            ecPackageAmount: this.ecPackageAmount,
            ecStopAmount: this.ecStopAmount,
            fuelAmount: this.fuelAmount,
            surgeAmount: this.surgeAmount,
        }
    }
}